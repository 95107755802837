import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/img/lodgix-icon-color-2.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "ldx-menu-logo",
  alt: "Logo",
  src: _imports_0
}
const _hoisted_3 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_ctx.accessToken)
    ? (_openBlock(), _createBlock(_component_ion_menu, {
        key: 0,
        mode: "ios",
        side: "start",
        "content-id": "content"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, {
            class: "ion-no-border",
            style: {"background-color":"#f0f2f5"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { color: "#F0F2F5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    lines: "none",
                    class: "ldx-nav-header"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_avatar, { slot: "start" }, {
                        default: _withCtx(() => [
                          (_ctx.store.companyLogo != '' && _ctx.store.companyLogo != null)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "ldx-menu-logo",
                                alt: "Logo",
                                src: _ctx.store.companyLogo
                              }, null, 8, _hoisted_1))
                            : _createCommentVNode("", true),
                          (_ctx.store.companyLogo == '')
                            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          (_ctx.store.companyName != '')
                            ? (_openBlock(), _createBlock(_component_ion_list_header, {
                                key: 0,
                                style: {"padding":"0px","font-size":"clamp(0.8rem, 4.25vw - 1.5rem, 0.9rem)"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.store.companyName), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.store.companyEmail != '')
                            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                                key: 1,
                                style: {"font-size":"clamp(0.5rem, 4.25vw - 1.5rem, 0.65rem)","font-weight":"bold"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.store.companyEmail), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.store.companyName == '')
                            ? (_openBlock(), _createBlock(_component_ion_list_header, {
                                key: 2,
                                style: {"padding":"0px","font-size":"clamp(0.8rem, 4.25vw - 1.5rem, 0.9rem)"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.store.userFirstName) + " " + _toDisplayString(_ctx.store.userLastName), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.store.companyEmail == '')
                            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                                key: 3,
                                style: {"font-size":"clamp(0.5rem, 4.25vw - 1.5rem, 0.65rem)","font-weight":"bold"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.store.userEmail), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_searchbar, {
            debounce: 0,
            onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchChange($event)))
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              (_ctx.store.debug)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.accessToken), 1)
                      ]),
                      _: 1
                    }),
                    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_component_ion_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeAccessToken()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.refreshOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_list, { style: {"padding":"0px","margin":"0px"} }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideMenu, (page, index) => {
                    return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      "auto-hide": false,
                      key: index,
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: _normalizeStyle([
                            _ctx.activePage.toLowerCase() === page.url.toLowerCase()
                                ? 'background: rgba(var(--ion-color-primary-rgb), 0.14) !important'
                                : '',
                        ])
                        }, [
                          (
                                ((page.keyWords.toLocaleLowerCase().includes(_ctx.searchValue) && _ctx.searchValue != '') ||
                                    _ctx.searchValue == '') &&
                                !(page.url == '/support' && _ctx.getPlatform() === 'web') &&
                                _ctx.checkScope(page.scopes) &&
                                _ctx.checkRoles(page.roles)
                            )
                            ? (_openBlock(), _createBlock(_component_ion_item, {
                                key: 0,
                                lines: "none",
                                onClick: ($event: any) => (_ctx.navigatePage(page.url))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    ios: _ctx.icon(page),
                                    md: _ctx.icon(page),
                                    color: _ctx.activePage.toLowerCase() === page.url.toLowerCase() ? 'primary' : 'medium'
                                  }, null, 8, ["ios", "md", "color"]),
                                  _createVNode(_component_ion_label, {
                                    color: _ctx.activePage.toLowerCase() === page.url.toLowerCase() ? 'primary' : 'dark'
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.title(page)), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["color"]),
                                  (
                                    page.title === 'Notifications' &&
                                    _ctx.store.pushNotificationUnreadCount > 0 &&
                                    _ctx.store.pushNotificationUnreadCount < 100
                                )
                                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                                        key: 0,
                                        color: "danger"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.store.pushNotificationUnreadCount), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  (page.title === 'Notifications' && _ctx.store.pushNotificationUnreadCount >= 100)
                                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                                        key: 1,
                                        color: "danger"
                                      }, {
                                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                                          _createTextVNode("99+")
                                        ])),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ], 4)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}