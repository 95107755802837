import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"padding-bottom":"100px","max-width":"var(--max-width)","margin":"auto"} }
const _hoisted_2 = {
  class: "ion-text-center",
  style: {"padding-top":"10px"}
}
const _hoisted_3 = {
  key: 0,
  style: {"padding-top":"15px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_WidgetComponent = _resolveComponent("WidgetComponent")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        class: "ldx-login-content",
        fullscreen: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_card, { style: {"margin":"15px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, { style: {"font-size":"30px","text-align":"center"} }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode("Welcome to Lodgix")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ion_label, { style: {"font-size":"20px","font-weight":"700"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.dynamicWelcomeMessage), 1)
                        ]),
                        _: 1
                      }),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                      _createVNode(_component_ion_label, { style: {"font-size":"20px","font-weight":"700"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.date), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { style: {"margin":"15px","margin-bottom":"0px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list, {
                      lines: "full",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainMenu, (item, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (_ctx.checkScope(item.scopes) && _ctx.checkRoles(item.roles))
                              ? (_openBlock(), _createBlock(_component_ion_item, {
                                  key: 0,
                                  style: {"cursor":"pointer"},
                                  onClick: ($event: any) => (_ctx.AppFunctions.transitionClear(item.url)),
                                  lines: index === _ctx.mainMenu.length - 1 ? 'none' : 'full',
                                  detail: true
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      style: {"padding-bottom":"5px","padding-top":"5px"},
                                      icon: item.icon,
                                      color: item.color,
                                      slot: "start"
                                    }, null, 8, ["icon", "color"]),
                                    _createVNode(_component_ion_label, { style: {"font-size":"larger","padding-bottom":"5px","padding-top":"5px"} }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("strong", null, _toDisplayString(item.label), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (item.url === '/notifications')
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          (
                                                _ctx.store.pushNotificationUnreadCount > 0 &&
                                                _ctx.store.pushNotificationUnreadCount < 100
                                            )
                                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                                key: 0,
                                                color: "danger"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.store.pushNotificationUnreadCount), 1)
                                                ]),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          (_ctx.store.pushNotificationUnreadCount >= 100)
                                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                                key: 1,
                                                color: "danger"
                                              }, {
                                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                  _createTextVNode("99+")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick", "lines"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.widgetAccess())
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_WidgetComponent)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}