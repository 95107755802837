import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Material Expense" }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "medium",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.confirm()))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Save")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { style: {"margin-right":"15px"} }, {
              default: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("label", null, "Date", -1)),
                _createVNode(_component_ion_datetime_button, {
                  slot: "end",
                  datetime: "dateMaterialExpense"
                }),
                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_datetime, {
                      id: "dateMaterialExpense",
                      presentation: "date",
                      modelValue: _ctx.date,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                      "format-options": _ctx.formatOptions,
                      "show-default-buttons": true
                    }, null, 8, ["modelValue", "format-options"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_textarea, {
                  autoGrow: "",
                  modelValue: _ctx.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
                  labelPlacement: "floating",
                  label: "Description",
                  counter: true,
                  maxlength: "128"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { style: {"margin-right":"15px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.quantity,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.quantity) = $event)),
                  type: "number",
                  label: "Quantity",
                  labelPlacement: "floating"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { style: {"margin-right":"15px"} }, {
              default: _withCtx(() => [
                _cache[7] || (_cache[7] = _createElementVNode("label", null, "Price", -1)),
                _createVNode(_component_CurrencyInput, {
                  slot: "end",
                  modelValue: _ctx.price,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.price) = $event)),
                  options: { currency: _ctx.store.userCurrency }
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}