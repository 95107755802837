import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"max-width":"var(--max-width)","text-align":"left","margin":"auto"} }
const _hoisted_2 = {
  style: {"background-color":"white","margin":"15px","padding":"20px","border-radius":"10px","box-shadow":"0px 0px 15px 1px rgb(0 0 0 / 10%)","border-color":"black","border-width":"1px"},
  class: ""
}
const _hoisted_3 = { class: "content-card-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuestComponent = _resolveComponent("GuestComponent")!
  const _component_DateSelectorComponent = _resolveComponent("DateSelectorComponent")!
  const _component_PropertyComponent = _resolveComponent("PropertyComponent")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_QuoteComponent = _resolveComponent("QuoteComponent")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { "page-title": "Create Reservation" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_GuestComponent),
          _createVNode(_component_DateSelectorComponent, _normalizeProps(_guardReactiveProps(_ctx.dateProps)), null, 16),
          _createVNode(_component_PropertyComponent, _normalizeProps(_guardReactiveProps(_ctx.isMinimal)), null, 16),
          _createVNode(_component_ion_card_header, {
            style: {"padding-bottom":"10px"},
            class: "ldx-section-start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_title, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Extra Information")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, { style: {"font-weight":"700"} }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Reservation Type")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_list, { class: "ldx-reservation-form" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    "interface-options": _ctx.reservationTypeOptions,
                    interface: "alert",
                    modelValue: _ctx.ReservationType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ReservationType) = $event)),
                    style: {"cursor":"pointer"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "guest_stay" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Guest")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "owner_stay" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Owner")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["interface-options", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        (_ctx.store.reservationSelectedPropertyId != '')
          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_QuoteComponent),
                    _createElementVNode("div", _hoisted_3, [
                      (_ctx.isAllComplete())
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitTemporaryReservation())),
                            style: {"width":"100%","--border-radius":"10px"},
                            color: "primary"
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Continue")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isAllComplete())
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitError())),
                            style: {"width":"-webkit-fill-available","--border-radius":"10px"},
                            color: "primary"
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("Continue")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}