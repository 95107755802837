import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"65px","height":"50px"} }
const _hoisted_2 = {
  "aria-hidden": "true",
  focusable: "false",
  "data-prefix": "fa",
  "data-icon": "sign-out-alt",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512",
  class: "svg-inline--fa fa-sign-out-alt fa-w-16 fa-3x",
  style: {"color":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { style: {"margin-bottom":"10px","margin-top":"10px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, { style: {"padding":"5px","background-image":"linear-gradient(to right, rgb(240, 148, 98), rgb(226, 179, 152))"} }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { style: {"font-size":"20px","font-weight":"800","color":"white","display":"flex"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.store.departureAmount), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"font-size":"20px","font-weight":"800","color":"white"} }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Departures")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
                      _createElementVNode("path", {
                        fill: "currentColor",
                        d: "M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z",
                        class: ""
                      }, null, -1)
                    ])))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}