<template>
    <modal-base-layout ref="modalBaseLayoutRef" title="Select / Create Guest">
        <template #header>
            <div>
                <div style="display: flex; width: -webkit-fill-available">
                    <div style="width: 100%">
                        <ion-list class="ldx-input">
                            <ion-icon :src="person"></ion-icon>
                            <ion-item mode="md" lines="full" style="width: -webkit-fill-available">
                                <ion-input
                                    label="First Name"
                                    label-placement="floating"
                                    autocapitalize="true"
                                    v-model="FirstName"
                                    placeholder="John"
                                ></ion-input>
                            </ion-item>
                        </ion-list>
                        <ion-text color="danger">
                            <p
                                v-if="!validateName(FirstName) && FirstName != ''"
                                style="text-align: center; margin: 5px"
                            >
                                First Name Invalid Format
                            </p>
                        </ion-text>
                    </div>
                    <div style="width: 100%">
                        <ion-list class="ldx-input">
                            <ion-icon :src="person"></ion-icon>
                            <ion-item mode="md" lines="full" style="width: -webkit-fill-available">
                                <ion-input
                                    autocapitalize="true"
                                    label-placement="floating"
                                    label="Last Name"
                                    v-model="LastName"
                                    placeholder="Doe"
                                ></ion-input>
                            </ion-item>
                        </ion-list>
                        <ion-text color="danger">
                            <p
                                v-if="!validateLastName(LastName) && LastName != ''"
                                style="text-align: center; margin: 5px"
                            >
                                Last Name Invalid Format
                            </p>
                        </ion-text>
                    </div>
                </div>
                <div style="width: -webkit-fill-available">
                    <ion-list class="ldx-input">
                        <ion-icon :src="mail"></ion-icon>
                        <ion-item mode="md" lines="full" style="width: -webkit-fill-available">
                            <ion-input
                                v-model="Email"
                                label-placement="floating"
                                label="Email"
                                type="email"
                                placeholder="JohnDoe@example.com"
                            ></ion-input>
                        </ion-item>
                    </ion-list>
                    <ion-text color="danger">
                        <p v-if="!validateEmail(Email) && Email != ''" style="text-align: center; margin: 5px">
                            Invalid Email
                        </p>
                    </ion-text>
                </div>
            </div>
        </template>

        <div style="text-align: left; margin: auto">
            <div>
                <perfect-scrollbar>
                    <template v-for="(item, index) in guestList" :key="index">
                        <ion-item
                            class="ion-no-padding"
                            lines="full"
                            v-if="
                                isMatch(
                                    item['first_name'],
                                    item['last_name'],
                                    item['email'],
                                    FirstName,
                                    LastName,
                                    Email
                                ) &&
                                item['first_name'] === FirstName &&
                                item['last_name'] === LastName &&
                                item['email'] === Email
                            "
                            @click="fillGuestInfo(index, item)"
                            :key="index"
                            :value="item"
                            style="cursor: pointer"
                        >
                            <ion-label style="padding-left: 15px">
                                <strong>{{ item['first_name'] }} {{ item['last_name'] }}</strong>
                                - {{ item['email'] }}
                            </ion-label>
                            <img v-if="item['is_vip']" src="@/assets/icons/vip.svg" />
                            <img v-if="item['is_blocklist']" src="@/assets/icons/blocklist.svg" />
                            <ion-icon style="margin-left: 5px" color="black" :icon="checkmarkCircleOutline"></ion-icon>
                        </ion-item>
                        <ion-item
                            class="ion-no-padding"
                            lines="full"
                            v-if="
                                isMatch(
                                    item['first_name'],
                                    item['last_name'],
                                    item['email'],
                                    FirstName,
                                    LastName,
                                    Email
                                ) &&
                                (item['first_name'] != FirstName ||
                                    item['last_name'] != LastName ||
                                    item['email'] != Email)
                            "
                            @click="fillGuestInfo(index, item)"
                            :key="index"
                            :value="item"
                            style="cursor: pointer"
                        >
                            <ion-label style="padding-left: 15px">
                                <strong>{{ item['first_name'] }} {{ item['last_name'] }}</strong>
                                - {{ item['email'] }}
                            </ion-label>
                            <img v-if="item['is_vip']" src="@/assets/icons/vip.svg" />
                            <img v-if="item['is_blocklist']" src="@/assets/icons/blocklist.svg" />
                        </ion-item>
                    </template>
                </perfect-scrollbar>
            </div>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="clearGuest()">Clear</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel">Cancel</ion-button>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button
                            class="modal-footer-button"
                            color="primary"
                            size="medium"
                            @click="
                                validateEmail(Email) && validateName(FirstName) && validateLastName(LastName)
                                    ? confirm()
                                    : basicAlert(
                                          'Invalid Guest Information',
                                          '',
                                          'Please enter a valid guest name and email address.'
                                      )
                            "
                        >
                            Accept</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import AppAPI from '@/services/AppAPI';
    import CommonFunctions from '@/services/AppFunctions';
    import { useStore } from '@/store/store';
    import { defineComponent, ref, onMounted, watch } from 'vue';
    import {
        arrowBackOutline,
        checkmarkCircleOutline,
        personCircleOutline,
        reader,
        closeCircle,
        searchCircle,
        person,
        mail,
    } from 'ionicons/icons';
    import { basicAlert } from '@/services/AlertController';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const modalBaseLayoutRef = ref();
            const FirstName = ref(store.reservationFirstName);
            const LastName = ref(store.reservationLastName);
            const Email = ref(store.reservationEmail);
            const guestId = ref(store.reservationGuestId);
            const guestList = ref([] as any);

            const cancel = () => {
                if (FirstName.value === '' && LastName.value === '' && Email.value === '') {
                    saveData();
                }
                modalBaseLayoutRef.value.cancel();
            };

            const saveData = () => {
                store.changeReservationFirstName(FirstName.value);
                store.changeReservationLastName(LastName.value);
                store.changeReservationEmail(Email.value);
                store.changeReservationGuestId(guestId.value);
            };

            const confirm = () => {
                saveData();
                modalBaseLayoutRef.value.confirm({
                    FirstName: FirstName.value,
                    LastName: LastName.value,
                    Email: Email.value,
                    GuestId: guestId.value,
                });
            };

            const fillGuestInfo = (index: any, item: any) => {
                FirstName.value = item.first_name;
                LastName.value = item.last_name;
                Email.value = item.email;
                guestId.value = item.id;
            };

            const validateEmail = (email: string) => {
                var isRealEmail = CommonFunctions.validateEmail(email);
                return isRealEmail;
            };

            const validateName = (name: string) => {
                var isRealName = CommonFunctions.validateFirstName(name);
                return isRealName;
            };

            const validateLastName = (name: string) => {
                var isRealName = CommonFunctions.validateLastName(name);
                return isRealName;
            };

            const isMatch = (
                itemFirstName: string,
                itemLastName: string,
                itemEmail: string,
                FirstName: string,
                LastName: string,
                Email: string
            ) => {
                if (
                    itemFirstName.toLowerCase().includes(FirstName.toLocaleLowerCase()) &&
                    itemLastName.toLowerCase().includes(LastName.toLocaleLowerCase()) &&
                    itemEmail.toLowerCase().includes(Email.toLocaleLowerCase())
                ) {
                    return true;
                } else {
                    return false;
                }
            };

            const searchGuests = (email: string, firstName: string, lastName: string) => {
                var response = AppAPI.searchGuests(email, firstName, lastName);

                response.then((value) => {
                    var tmpList = value.data;
                    tmpList.sort((a: any, b: any) => a['full_name'].localeCompare(b['full_name']));
                    guestList.value = tmpList;
                });
            };

            const clearGuest = () => {
                FirstName.value = '';
                LastName.value = '';
                Email.value = '';
                store.changeReservationFirstName(FirstName.value);
                store.changeReservationLastName(LastName.value);
                store.changeReservationEmail(Email.value);
            };

            const searchChange = (event: any) => {
                const query = event.target.value.toLowerCase();
                searchGuests(query, '', '');
            };

            onMounted(() => {
                searchGuests(Email.value, FirstName.value, LastName.value);
            });

            watch([() => FirstName.value, () => LastName.value, () => Email.value], () => {
                searchGuests(Email.value, FirstName.value, LastName.value);
            });

            return {
                arrowBackOutline,
                checkmarkCircleOutline,
                personCircleOutline,
                reader,
                closeCircle,
                searchCircle,
                person,
                mail,
                FirstName,
                LastName,
                Email,
                guestList,
                modalBaseLayoutRef,
                basicAlert,
                cancel,
                confirm,
                fillGuestInfo,
                validateEmail,
                validateName,
                isMatch,
                searchGuests,
                clearGuest,
                searchChange,
                validateLastName,
            };
        },
    });
</script>

<style scoped>
    ion-list {
        margin: 5px;
    }
    .ldx-input {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        width: -webkit-fill-available;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: medium;
    }

    .ldx-input ion-label {
        display: flex;
        align-items: center;
    }

    .ldx-input ion-item {
        height: fit-content;
    }

    .ldx-input ion-icon {
        width: 30px;
        height: 100%;
        margin: 0px;
        padding: 10px;
        background: #d8d8d8;
        color: #767676;
    }

    .ps {
        height: 100%;
    }
</style>
