<template>
    <base-layout :page-title="`Create ${store.isQuoteOnly ? 'Quote' : 'Reservation'}`">
        <div class="container" style="max-width: var(--max-width); text-align: left; margin: auto">
            <div class="container-section" v-if="!isGoHome">
                <div v-if="!isSubmitted">
                    <!--
                        Reservation Summary
                    -->

                    <ion-card>
                        <ion-card-header style="background: rgb(32, 70, 155); margin: 0px; padding-bottom: 15px">
                            <p style="font-weight: 700; font-size: large; margin: 0px; color: white">
                                {{ store.isQuoteOnly ? 'Quote' : 'Reservation' }} Summary
                            </p>
                        </ion-card-header>
                        <ion-card-content style="padding: 0px; padding-bottom: 5px">
                            <ion-list lines="full">
                                <ion-item style="overflow: auto">
                                    <ion-label style="padding-right: 5px">Property:</ion-label>
                                    <ion-label
                                        style="text-align: end; text-wrap: nowrap"
                                        v-if="store.reservationSelectedProperty != ''"
                                        >{{ store.reservationSelectedProperty }}</ion-label
                                    >
                                </ion-item>
                                <ion-item style="overflow: auto">
                                    <ion-label>Dates:</ion-label>
                                    <ion-label style="text-align: end; text-wrap: nowrap"
                                        >{{ getDateToString(store.reservationStartDate) }} -
                                        {{ getDateToString(store.reservationEndDate) }}</ion-label
                                    >
                                </ion-item>
                                <ion-item style="overflow: auto">
                                    <ion-label>Nights:</ion-label>
                                    <ion-label style="text-align: end; text-wrap: nowrap">{{
                                        differenceInDaysString
                                    }}</ion-label>
                                </ion-item>
                                <ion-item style="overflow: auto">
                                    <ion-label>Confirmation Amount:</ion-label>
                                    <ion-label style="text-align: end; text-wrap: nowrap">{{
                                        formatPrice(parseFloat(store.reservationConfirmationAmount))
                                    }}</ion-label>
                                </ion-item>
                                <ion-item :lines="store.isQuoteOnly ? 'full' : 'none'" style="overflow: auto">
                                    <ion-label>Total with Deposits:</ion-label>
                                    <ion-label style="text-align: end; text-wrap: nowrap">{{
                                        formatPrice(parseFloat(store.reservationTotalWithDeposits))
                                    }}</ion-label>
                                </ion-item>
                            </ion-list>

                            <ion-button
                                style="
                                    margin-top: 15px;
                                    width: -webkit-fill-available;
                                    --border-radius: 10px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                "
                                size="small"
                                class="ldx-payment-btn"
                                color="primary"
                                @click="finishReservationButton(true)"
                                v-if="store.isQuoteOnly"
                                >{{ loading2 ? 'Loading...' : 'Create Quote' }}</ion-button
                            >
                            <ion-button
                                style="
                                    margin-top: 15px;
                                    width: -webkit-fill-available;
                                    --border-radius: 10px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                "
                                size="small"
                                class="ldx-payment-btn"
                                color="danger"
                                @click="clearReservationSave()"
                                href="/home"
                                v-if="store.isQuoteOnly"
                                >Cancel</ion-button
                            >
                        </ion-card-content>
                    </ion-card>
                </div>

                <!--
                    No Payment Options
                -->
                <ion-card v-if="!isSubmitted && !isPaymentAvailable && !store.isQuoteOnly">
                    <ion-card-header style="background: rgb(32, 70, 155); margin: 0px; padding-bottom: 15px">
                        <p style="font-weight: 700; font-size: large; margin: 0px; color: white">
                            No Payment Option Avaliable
                        </p>
                    </ion-card-header>
                    <ion-card-content style="padding-top: 10px">
                        <ion-label style="font-weight: 700" text-wrap
                            >A Stripe payment method has not been added to your Lodgix account.</ion-label
                        >
                        <div style="padding-top: 25px">
                            <ion-button
                                style="
                                    margin-top: 15px;
                                    width: -webkit-fill-available;
                                    --border-radius: 10px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                "
                                size="small"
                                class="ldx-payment-btn"
                                color="primary"
                                @click="finishReservationButton()"
                                v-if="!store.isQuoteOnly"
                                >{{ loading2 ? 'Loading...' : 'Finish Reservation' }}</ion-button
                            >
                            <ion-button
                                style="
                                    margin-top: 15px;
                                    width: -webkit-fill-available;
                                    --border-radius: 10px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                "
                                size="small"
                                class="ldx-payment-btn"
                                color="primary"
                                @click="finishReservationButton(true)"
                                >{{ loading2 ? 'Loading...' : 'Create Quote' }}</ion-button
                            >
                            <ion-button
                                style="
                                    margin-top: 15px;
                                    width: -webkit-fill-available;
                                    --border-radius: 10px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                "
                                size="small"
                                class="ldx-payment-btn"
                                color="danger"
                                @click="clearReservationSave()"
                                href="/home"
                                >Cancel</ion-button
                            >
                        </div>
                    </ion-card-content>
                </ion-card>

                <!--
                    Billing and Card Input
                -->
                <ion-card v-if="!isSubmitted && isPaymentAvailable && !store.isQuoteOnly">
                    <ion-card-header style="background: rgb(32, 70, 155); margin: 0px; padding-bottom: 15px">
                        <p style="font-weight: 700; font-size: large; margin: 0px; color: white">Billing Information</p>
                    </ion-card-header>
                    <ion-card-content class="ion-no-padding">
                        <form @submit.prevent="handleSubmit" style="margin: 13px">
                            <ion-list class="ldx-reservation-form">
                                <ion-item lines="full">
                                    <ion-input
                                        label="First Name:"
                                        type="text"
                                        name="first_name"
                                        class="ldx-payment-input"
                                        v-model="billingFirstName"
                                    />
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-input
                                        type="text"
                                        name="last_name"
                                        label="Last Name:"
                                        class="ldx-payment-input"
                                        v-model="billingLastName"
                                    />
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-input
                                        type="email"
                                        name="email"
                                        label="Email:"
                                        class="ldx-payment-input"
                                        v-model="billingEmail"
                                    />
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-input
                                        type="text"
                                        name="address"
                                        label="Address:"
                                        class="ldx-payment-input"
                                        v-model="billingAddress"
                                    />
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-input
                                        type="text"
                                        name="city"
                                        label="City:"
                                        class="ldx-payment-input"
                                        v-model="billingCity"
                                    />
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-select name="state" interface="alert" label="State" v-model="state">
                                        <ion-select-option
                                            v-for="n in store.reservationBillingStateList"
                                            :key="n.id"
                                            :value="n.id"
                                            >{{ n.name }}</ion-select-option
                                        >
                                    </ion-select>
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-select name="country" interface="alert" label="Country:" v-model="country">
                                        <ion-select-option
                                            v-for="n in store.reservationBillingCountryList"
                                            :key="n.id"
                                            :value="n.id"
                                            >{{ n.name }}</ion-select-option
                                        >
                                    </ion-select>
                                </ion-item>

                                <ion-item lines="full">
                                    <ion-input
                                        type="text"
                                        name="zip"
                                        label="Zip:"
                                        class="ldx-payment-input"
                                        v-model="billingZip"
                                    />
                                </ion-item>
                            </ion-list>

                            <ion-card-header class="ldx-section-start">
                                <ion-card-title>Credit Card</ion-card-title>
                            </ion-card-header>
                            <div class="ldx-credit-card-input">
                                <div id="stripe-element-mount-point" />
                            </div>

                            <div class="content-section" style="padding-top: 30px; padding-bottom: 0px">
                                <ion-card-header class="ldx-section-start" style="padding-bottom: 10px">
                                    <ion-label style="font-size: smaller"
                                        >Use box below to customize payment amount or continue without credit
                                        card.</ion-label
                                    >
                                    <ion-card-title>Customize Amount</ion-card-title>
                                </ion-card-header>
                                <ion-list class="ldx-reservation-form">
                                    <ion-item>
                                        <ion-select interface="action-sheet" label="Amount" v-model="this.payBox">
                                            <ion-select-option value="payConfirmationAmount" slot="end"
                                                >Confirmation
                                                {{
                                                    formatPrice(parseFloat(store.reservationConfirmationAmount))
                                                }}</ion-select-option
                                            >
                                            <ion-select-option value="payTotal" slot="end"
                                                >Total with Deposits
                                                {{
                                                    formatPrice(parseFloat(store.reservationTotalWithDeposits))
                                                }}</ion-select-option
                                            >
                                            <ion-select-option value="payCustomAmount" slot="end"
                                                >Custom</ion-select-option
                                            >
                                            <ion-select-option value="payLater" slot="end"
                                                >Continue Without Credit Card</ion-select-option
                                            >
                                        </ion-select>
                                    </ion-item>
                                </ion-list>
                                <ion-list class="ldx-reservation-form">
                                    <ion-item v-if="this.payBox === 'payCustomAmount'">
                                        <ion-input
                                            class="ldx-payment-input"
                                            inputmode="decimal"
                                            :label="store.currencySymbol"
                                            v-model="amountToPay"
                                        ></ion-input>
                                    </ion-item>
                                </ion-list>
                                <div v-if="this.payBox !== 'payLater'">
                                    <ion-label v-if="amountToPayError && amountToPay > 0 && !isInputError"
                                        >Custom payment amount is over the total with deposits.</ion-label
                                    >
                                    <ion-label v-if="amountToPayError && amountToPay < 0 && !isInputError"
                                        >Custom payment amount is below 0.</ion-label
                                    >
                                    <ion-label
                                        v-if="
                                            amountToPayError &&
                                            (amountToPay == '' || amountToPay == null) &&
                                            !isInputError
                                        "
                                        >Custom payment amount is invalid.</ion-label
                                    >
                                    <ion-label v-if="isInputError"
                                        >Enter a whole number or up to two decimal places. No letters, commas, or
                                        symbols allowed.</ion-label
                                    >
                                </div>
                                <div v-if="this.payBox === 'payLater'" style="padding-top: 5px">
                                    <ion-button
                                        style="
                                            margin-top: 15px;
                                            width: -webkit-fill-available;
                                            --border-radius: 10px;
                                            padding-left: 15px;
                                            padding-right: 15px;
                                        "
                                        size="small"
                                        class="ldx-payment-btn"
                                        color="primary"
                                        @click="finishReservationButton()"
                                        >{{ loading2 ? 'Loading...' : 'Complete Without Card' }}</ion-button
                                    >
                                    <ion-button
                                        style="
                                            margin-top: 15px;
                                            width: -webkit-fill-available;
                                            --border-radius: 10px;
                                            padding-left: 15px;
                                            padding-right: 15px;
                                        "
                                        size="small"
                                        class="ldx-payment-btn"
                                        color="primary"
                                        @click="finishReservationButton(true)"
                                        >{{ loading2 ? 'Loading...' : 'Create Quote' }}</ion-button
                                    >
                                    <ion-button
                                        style="
                                            margin-top: 15px;
                                            width: -webkit-fill-available;
                                            --border-radius: 10px;
                                            padding-left: 15px;
                                            padding-right: 15px;
                                        "
                                        size="small"
                                        class="ldx-payment-btn"
                                        color="danger"
                                        @click="clearReservationSave()"
                                        href="/home"
                                        >Cancel</ion-button
                                    >
                                </div>
                            </div>

                            <div class="ldx-field-pay" v-if="this.payBox !== 'payLater'" style="">
                                <ion-button
                                    style="
                                        margin-top: 15px;
                                        width: -webkit-fill-available;
                                        --border-radius: 10px;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                    "
                                    type="submit"
                                    class="ldx-payment-btn"
                                    :class="{ dis: loading }"
                                    color="primary"
                                    size="small"
                                    :disabled="!store.reservationStripeIsCard || this.amountToPayError || loading"
                                >
                                    {{
                                        loading ? 'Loading...' : 'Charge: ' + formatPrice(parseFloat(this.amountToPay))
                                    }}
                                </ion-button>
                                <ion-button
                                    style="
                                        margin-top: 15px;
                                        width: -webkit-fill-available;
                                        --border-radius: 10px;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                    "
                                    size="small"
                                    class="ldx-payment-btn"
                                    color="primary"
                                    @click="finishReservationButton(true)"
                                    >{{ loading2 ? 'Loading...' : 'Create Quote' }}</ion-button
                                >
                                <ion-button
                                    style="
                                        margin-top: 15px;
                                        width: -webkit-fill-available;
                                        --border-radius: 10px;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                    "
                                    class="ldx-payment-btn"
                                    size="small"
                                    color="danger"
                                    @click="clearReservationSave()"
                                    href="/home"
                                    >Cancel</ion-button
                                >
                            </div>
                        </form>
                    </ion-card-content>
                </ion-card>

                <div v-if="!isSubmitted && isPaymentAvailable" style="padding-bottom: 100px"></div>

                <!--
                    Submitted
                -->
                <div v-if="isSubmitted">
                    <ion-card>
                        <ion-card-header style="background: rgb(32, 70, 155); margin: 0px; padding-bottom: 15px">
                            <p style="font-weight: 700; font-size: large; margin: 0px; color: white">
                                {{ isError ? 'Alert' : 'Confirmation' }}
                            </p>
                        </ion-card-header>
                        <ion-card-content style="padding-top: 10px">
                            <p style="font-size: 20px; font-weight: 700">
                                {{
                                    `${
                                        isError
                                            ? 'Error'
                                            : `${
                                                  confirmationDetails.state == 'QUOTE'
                                                      ? 'Quote Created'
                                                      : 'Reservation Created'
                                              }`
                                    }`
                                }}
                            </p>

                            <div class="content-section" v-if="isPaymentAvailable">
                                <p style="font-size: 17px">
                                    {{
                                        `The ${confirmationDetails.state == 'QUOTE' ? 'quote' : 'reservation'} ${
                                            isCreated ? 'has been' : 'was not'
                                        } created.`
                                    }}
                                </p>
                                <br />
                                <p style="font-size: 17px">
                                    {{ `Notice: The card ${isCardCharged ? 'has been' : 'was not'} charged. ` }}
                                </p>
                                <br />
                                <p v-if="isCreated" style="font-weight: bolder; font-size: 20px">
                                    {{ confirmationDetails.state == 'QUOTE' ? 'Quote #' : 'Invoice #'
                                    }}{{ store.reservationInvoiceId }}
                                </p>
                            </div>

                            <div class="content-section" v-if="!isPaymentAvailable">
                                <p style="font-size: 17px">
                                    The {{ confirmationDetails.state == 'QUOTE' ? 'quote' : 'reservation' }}
                                    {{ isCreated ? ' has been created.' : ' was not created.' }}
                                </p>
                                <br />
                                <p v-if="isCreated" style="font-weight: bolder; font-size: 20px">
                                    {{ confirmationDetails.state == 'QUOTE' ? 'Quote #' : 'Invoice #'
                                    }}{{ store.reservationInvoiceId }}
                                </p>
                            </div>

                            <ion-button
                                class="ldx-payment-btn"
                                size="small"
                                color="primary"
                                @click="clearReservationSave()"
                                href="/home"
                                style="
                                    margin-top: 15px;
                                    width: -webkit-fill-available;
                                    --border-radius: 10px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                "
                                >Home</ion-button
                            >
                        </ion-card-content>
                    </ion-card>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
    import { loadStripe } from '@stripe/stripe-js';
    import { defineComponent, onMounted, ref, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from '@/store/store';
    import CommonFunctions from '@/services/AppFunctions';
    import AppAPI from '@/services/AppAPI';
    import { format, formatDistance } from 'date-fns';
    import { showLoading, hideLoading } from '@/services/Loading';
    import { basicAlert } from '@/services/AlertController';

    export default defineComponent({
        components: {},
        setup() {
            const router = useRouter();
            const store = useStore();

            const isCreated = ref(false);
            const isCardCharged = ref(false);
            const isError = ref(false);

            let stripe = null;
            let loading = ref(false);
            let loading2 = ref(false);
            let elements = null;
            let cardElement = null;
            store.changeReservationStripeIsCard(false);
            const confirmationDetails = ref(null);

            const billingFirstName = ref(store.reservationFirstName);
            const billingLastName = ref(store.reservationLastName);
            const billingEmail = ref(store.reservationEmail);
            const billingAddress = ref(store.reservationBillingAddress);
            const billingCity = ref(store.reservationBillingCity);
            const billingZip = ref(store.reservationBillingZip);

            var differenceInDaysString;
            if (store.reservationStartDate != '' && store.reservationEndDate != '') {
                differenceInDaysString = formatDistance(
                    new Date(store.reservationStartDate),
                    new Date(store.reservationEndDate)
                );
                differenceInDaysString = differenceInDaysString.split(' ');
                differenceInDaysString = differenceInDaysString[0];
            }

            const isPaymentAvailable = computed(() => {
                return (
                    store.reservationSetupIntentClientSecret != '' && store.reservationSetupIntentClientSecret != null
                );
            });

            onMounted(async () => {
                if (
                    !store.isQuoteOnly &&
                    store.reservationSetupIntentClientSecret != '' &&
                    store.reservationSetupIntentClientSecret != null
                ) {
                    stripe = await loadStripe(store.reservationStripePublishableKey, {
                        stripeAccount: store.reservationStripeAccount,
                    });
                    const clientSecret = store.reservationSetupIntentClientSecret;

                    elements = stripe.elements(clientSecret);
                    cardElement = elements.create('card', {
                        hidePostalCode: true,
                        style: {
                            base: {
                                color: '#000',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSmoothing: 'antialiased',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: '#000',
                                },
                            },
                            invalid: {
                                fontFamily: 'Montserrat, sans-serif',
                                color: '#fe1d0d',
                                iconColor: '#fe1d0d',
                            },
                        },
                    });
                    cardElement.on('change', function (event) {
                        if (store.reservationStripeIsCard != event.complete) {
                            store.changeReservationStripeIsCard(event.complete);
                        }
                        if (event.error != null) {
                            basicAlert('Payment Error', '', event.error.message);
                        }
                    });
                    cardElement.mount('#stripe-element-mount-point');
                }
                loading.value = false;
                loading2.value = false;
            });

            async function handleSubmit(event) {
                if (loading.value) return;
                const loadingService = showLoading('Loading...');
                loading.value = true;
                let selectedCountryCode = '';
                try {
                    /*const { first_name, last_name, email, address, city, state, zip } = Object.fromEntries(
                        new FormData(event.target)
                    );*/

                    for (let i = 0; i < store.reservationBillingCountryList.length; i++) {
                        if (this.country == store.reservationBillingCountryList[i].id) {
                            this.selectedCountryCode = store.reservationBillingCountryList[i].code;
                            i = store.reservationBillingCountryList.length;
                        }
                    }
                    for (let i = 0; i < store.reservationBillingStateList.length; i++) {
                        if (this.state == store.reservationBillingStateList[i].id) {
                            this.state = store.reservationBillingStateList[i].code;
                            i = store.reservationBillingStateList.length;
                        }
                    }

                    const name = billingFirstName.value.concat(' ', billingLastName.value);
                    const billingDetails = {
                        name,
                        email: billingEmail.value,
                        address: {
                            city: billingCity.value,
                            line1: billingAddress.value,
                            state: this.state,
                            postal_code: billingZip.value,
                            country: this.selectedCountryCode,
                        },
                    };

                    try {
                        const response = await stripe.confirmCardSetup(store.reservationSetupIntentClientSecret, {
                            payment_method: {
                                card: cardElement,
                                billing_details: billingDetails,
                            },
                        });
                        if (response.error) {
                            isCardCharged.value = false;
                            loading.value = false;
                            hideLoading(loadingService);
                            basicAlert('Payment Error', '', response.error.message);
                        } else {
                            if (response.setupIntent.id != '') {
                                await AppAPI.sendFinishReservation(
                                    store.reservationInvoiceId,
                                    false,
                                    response.setupIntent.id,
                                    this.amountToPay
                                ).then((value) => {
                                    if (value?.data) {
                                        isCardCharged.value = true;
                                        isCreated.value = true;
                                        confirmationDetails.value = value.data;
                                        loading.value = false;
                                        hideLoading(loadingService);
                                        this.isSubmitted = true;
                                    } else {
                                        isCardCharged.value = false;
                                        isCreated.value = true;
                                        confirmationDetails.value = '';
                                        loading.value = false;
                                        hideLoading(loadingService);
                                        this.isSubmitted = true;
                                    }
                                });
                            }
                        }
                    } catch (error) {
                        isCardCharged.value = false;
                        isCreated.value = false;
                        hideLoading(loadingService);
                        basicAlert('Stripe Error', '', error.message);
                        router.push('/cart');
                        this.isSubmitted = false;
                        loading.value = false;
                    }
                } catch (error) {
                    hideLoading(loadingService);
                    isCardCharged.value = false;
                    isCreated.value = false;
                }
            }

            return {
                basicAlert,
                loading,
                loading2,
                differenceInDaysString,
                handleSubmit,
                store,
                confirmationDetails,
                isPaymentAvailable,
                isCardCharged,
                isCreated,
                isError,
                billingFirstName,
                billingLastName,
                billingEmail,
                billingAddress,
                billingCity,
                billingZip,
            };
        },
        methods: {
            clearReservationSave() {
                this.isGoHome = true;
                this.store.changeActivePage('/home');
                CommonFunctions.clearReservationStorage();
            },
            async finishReservationButton(isQuote = false) {
                const loadingService = await showLoading('Loading...');
                this.loading2 = true;
                try {
                    var apiResponse = await AppAPI.sendFinishReservation(this.store.reservationInvoiceId, isQuote);
                    hideLoading(loadingService);
                    this.loading2 = false;
                    if (apiResponse.data == null) {
                        this.basicAlert(
                            'Reservation Error',
                            '',
                            'An error has occured while trying to create reservation. This reservation may have already been submitted. Please check and if not try again.'
                        );
                        this.isError = true;
                        this.isCardCharged = false;
                        this.isCreated = false;
                        this.isSubmitted = true;
                        return;
                    }
                    this.confirmationDetails = apiResponse.data;
                    this.isSubmitted = true;
                    this.isCreated = true;
                    this.isCardCharged = false;
                } catch (error) {
                    hideLoading(loadingService);
                    this.loading2 = false;
                    this.isError = true;
                    this.isCardCharged = false;
                    this.isCreated = false;
                }
            },

            getDateToString(date) {
                if (date == '') {
                    return 'none';
                }
                var fromDateString = format(new Date(date), 'MM/dd/yyyy');
                return fromDateString;
            },
            formatPrice(value) {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: this.store.userCurrency,
                });
                return format.format(value);
            },
        },

        watch: {
            payBox: function () {
                //update amount to pay
                if (this.payBox === 'payConfirmationAmount') {
                    this.amountToPay = parseFloat(this.store.reservationConfirmationAmount);
                }
                if (this.payBox === 'payTotal') {
                    this.amountToPay = parseFloat(this.store.reservationTotalWithDeposits);
                }
                if (this.payBox === 'payLater') {
                    this.amountToPay = 0;
                }
            },
            amountToPay: function () {
                if (this.payBox !== 'payLater') {
                    var reg = /^(\d+.)*(\d+)$/.test(this.amountToPay.toString());
                    var reg2 = this.amountToPay.toString().includes(',');
                    //console.log(reg + "-" + reg2)
                    if (!reg || reg2) {
                        this.isInputError = true;
                        this.amountToPayError = true;
                    } else if (
                        this.amountToPay > parseFloat(this.store.reservationTotalWithDeposits) ||
                        this.amountToPay < 0 ||
                        this.amountToPay == ''
                    ) {
                        this.amountToPayError = true;
                        this.isInputError = false;
                    } else {
                        //check decimal amount
                        //console.log(this.amountToPay.toString().split('.'))
                        if (this.amountToPay.toString().split('.').length > 1) {
                            if (this.amountToPay.toString().split('.').length > 2) {
                                this.isInputError = true;
                                this.amountToPayError = true;
                            } else if (this.amountToPay.toString().split('.')[1].length > 2) {
                                this.isInputError = true;
                                this.amountToPayError = true;
                            } else {
                                //console.log(this.amountToPay.toString().split('.'))
                                this.amountToPayError = false;
                                this.isInputError = false;
                            }
                        } else {
                            //all good no issues
                            this.isInputError = false;
                            this.amountToPayError = false;
                        }
                    }
                }
            },
            country: function () {
                AppAPI.getStates(this.country).then((value) => {
                    //console.log(value.data)
                    this.store.changeReservationBillingStateList(value.data);
                });
            },
        },

        data() {
            return {
                isSubmitted: false,
                isGoHome: false,
                amountToPay: parseFloat(this.store.reservationConfirmationAmount),
                payBox: 'payConfirmationAmount',
                amountToPayError: false,
                isInputError: false,
                state: this.store.reservationBillingState,
                country: this.store.reservationBillingCountry,
            };
        },
    });
</script>

<style scoped>
    .ldx-section-start {
        padding-top: 0;
        padding-left: 0;
    }

    .content-section {
        padding-bottom: 32px;
    }
    .ldx-section-start ion-card-title {
        font-size: 20px;
    }
    /*.item-has-focus ion-item::part(native){
  background: var(--ion-color-primary);
  border: 1px solid var(--ion-color-medium-tint);
}*/
    .ldx-reservation-form ion-item::part(native) {
        background: var(--ion-color-light-tint);
        --background-activated: var(--ion-color-danger);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;
    }
    .ldx-reservation-form ion-label {
        margin: 0;
    }

    .ldx-reservation-form ion-icon {
        font-size: 14px;
    }

    .ldx-reservation-form .select-icon {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-reservation-form ion-select::part(placeholder),
    .ldx-reservation-form ion-select::part(text),
    .ldx-reservation-form ion-select::part(icon) {
        color: #000000;
        opacity: 1;
    }

    .ldx-reservation-form ion-icon.ldx-alt-icon {
        background: var(--ion-color-primary);
        color: #ffffff;
        padding: 0.5rem;
        font-size: 15px;
        border-radius: 5px;
        margin: 0;
    }

    .ldx-credit-card-input {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;

        font-size: 15px;
        margin: 0;
        padding: 10px;
        border-radius: 5px;
    }

    .ldx-header-icon {
        padding-right: 20px;
        position: relative;
        margin: -8px;
        height: 30px;
        width: 30px;
        margin-left: -5px;
    }
</style>
