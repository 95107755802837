import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: "Warning - Rules",
    background: "rgb(239, 246, 255)"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    size: "medium",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Proceed Anyway")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "The reservation violates some rules:", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.name
                }, [
                  _createElementVNode("p", null, [
                    _createElementVNode("b", null, _toDisplayString(item.name), 1)
                  ]),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.errors, (error) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: error.id
                      }, _toDisplayString(error), 1))
                    }), 128))
                  ])
                ]))
              }), 128)),
              _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
              _cache[5] || (_cache[5] = _createElementVNode("p", null, "Please press \"Proceed Anyway\" if this is correct.", -1))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}