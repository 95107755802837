import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"padding-bottom":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, { mode: "md" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, { style: {"font-family":"'Montserrat', sans-serif","font-weight":"700","text-align":"center"} }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$props.title), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.$slots.header)
      ? (_openBlock(), _createBlock(_component_ion_header, {
          key: 0,
          style: {"box-shadow":"0px 1px 5px 0px rgba(194, 194, 194, 1)"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, { style: {"--background":"white","padding":"0px !important"} }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "header")
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_content, {
      class: "ion-no-padding",
      style: _normalizeStyle({ '--background': _ctx.$props.background })
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 8, ["style"]),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createBlock(_component_ion_footer, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, {
              style: _normalizeStyle([{ '--background': _ctx.$props.footerBackground }, {"color":"black","padding":"0","padding-bottom":"env(safe-area-inset-bottom) !important"}])
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "footer")
              ]),
              _: 3
            }, 8, ["style"])
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 64))
}