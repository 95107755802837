<template>
    <ion-page>
        <div style="color: black; background: rgb(239, 246, 255); overflow-y: scroll; height: 100%">
            <housekeeper-component
                v-if="showHousekeeper"
                @apiFailed="oauthFailed($event)"
                @presentToast="toast"
                @openChecklist="openChecklist"
                @openCalendarTape="AppFunctions.transitionClear('/calendarPage')"
                :accessToken="accessToken"
                :urlBase="urlBase"
                :updateTrigger="updateTrigger"
                :language="store.lang"
                isApp
            ></housekeeper-component>
        </div>
    </ion-page>
</template>

<script lang="ts">
    import { useStore } from '@/store/store';
    import { defineComponent } from '@vue/runtime-core';
    import { alertCircle } from 'ionicons/icons';
    import LodgixOAuth2 from '@/services/OAuth2';
    import { infoToast, errorToast, successToast } from '@/services/Toast';
    import { ref, watch, onMounted, onBeforeMount } from 'vue';
    import ModalController from '@/services/ModalController';
    import { accessToken, refreshToken } from '@/services/OAuth2';
    import { isLocalDevelopment } from '@/utils/constants';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const urlBase = process.env.VUE_APP_URL_BASE;
            const updateTrigger = ref(0);

            const showHousekeeper = ref(false);

            const oauthFailed = async (data: string) => {
                if (store.UserEmail != '' && showHousekeeper.value) {
                    showHousekeeper.value = false;
                    const result = await LodgixOAuth2.refresh();
                    if (result) {
                        showHousekeeper.value = true;
                    } else {
                        LodgixOAuth2.logout();
                    }
                }
            };

            const toast = async (message: any) => {
                if (message.detail[1] == 'success') {
                    successToast(message.detail[0]);
                } else if (message.detail[1] == 'danger') {
                    errorToast(message.detail[0]);
                } else {
                    infoToast(message.detail[0]);
                }
            };

            const openChecklist = async (data: any) => {
                await ModalController.checklistModal(data.detail[0]);
                updateTrigger.value++;
            };

            onMounted(() => {
                if (process.env.VUE_APP_HOUSEKEEPER_LIVE_RELOAD === 'true' && isLocalDevelopment()) {
                    let link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = 'http://localhost:3500/src/styles/style.css';
                    document.head.appendChild(link);

                    const script = document.createElement('script');
                    script.type = 'module';
                    script.src = 'http://localhost:3500/src/main.ce.ts';
                    script.onload = () => {
                        showHousekeeper.value = true;
                    };
                    document.body.appendChild(script);
                } else {
                    const script = document.createElement('script');
                    script.type = 'module';
                    script.src = `housekeeper/housekeeper-component.js`;
                    script.onload = () => {
                        showHousekeeper.value = true;
                    };
                    script.onerror = () => {
                        console.error('Failed to load housekeeper-component script');
                    };
                    document.body.appendChild(script);
                }
            });

            watch(accessToken, async (newVal: any) => {
                if (newVal != '') {
                    showHousekeeper.value = false;
                    await new Promise((resolve) => setTimeout(resolve, 150));
                    showHousekeeper.value = true;
                }
            });

            return {
                urlBase,
                alertCircle,
                store,
                updateTrigger,
                accessToken,
                refreshToken,
                showHousekeeper,
                AppFunctions,
                oauthFailed,
                toast,
                openChecklist,
            };
        },
    });
</script>
