import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  hidden: "",
  style: {"width":"55px","height":"50px","margin-right":"5px"}
}
const _hoisted_2 = {
  "aria-hidden": "true",
  focusable: "false",
  "data-prefix": "fa",
  "data-icon": "user",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 448 512",
  class: "svg-inline--fa fa-user fa-w-14 fa-3x",
  style: {"color":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { style: {"margin-bottom":"10px","margin-top":"10px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, { style: {"padding":"5px","background-image":"linear-gradient(to right, rgb(145, 63, 63), rgb(219, 99, 99))"} }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { style: {"font-size":"20px","font-weight":"800","color":"white","display":"flex"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.store.inquiryDataCount), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"font-size":"20px","font-weight":"800","color":"white"} }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Inquiries")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    style: {"width":"70px","height":"70px","margin-right":"0px","color":"white"},
                    icon: _ctx.helpCircleOutline
                  }, null, 8, ["icon"]),
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
                      _createElementVNode("path", {
                        fill: "currentColor",
                        d: "M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z",
                        class: ""
                      }, null, -1)
                    ])))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}