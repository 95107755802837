<template>
    <modal-base-layout ref="modalBaseLayoutRef" title="Invoice Notes">
        <div style="text-align: left; padding: 5px">
            <ion-textarea v-model="tmpNotes" placeholder="Notes here..." autoGrow fill="solid"></ion-textarea>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="cancel()">Cancel</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="confirm()">Save</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { defineComponent, ref } from 'vue';

    export default defineComponent({
        components: {},
        props: {
            notes: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const modalBaseLayoutRef = ref();
            const tmpNotes = ref(props.notes);

            const cancel = () => {
                modalBaseLayoutRef.value.cancel();
            };

            const confirm = () => {
                modalBaseLayoutRef.value.confirm({
                    notes: tmpNotes.value,
                });
            };

            return {
                cancel,
                confirm,
                tmpNotes,
                modalBaseLayoutRef,
            };
        },
    });
</script>
