import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"max-width":"var(--max-width)","text-align":"left","margin":"auto"} }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"background-color":"white","margin":"15px","padding":"20px","border-radius":"10px","box-shadow":"0px 0px 15px 1px rgb(0 0 0 / 10%)","border-color":"black","border-width":"1px"} }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_DateSelectorComponent = _resolveComponent("DateSelectorComponent")!
  const _component_PropertyComponent = _resolveComponent("PropertyComponent")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { "page-title": "Create Block" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isSubmitted)
          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, { style: {"background":"rgb(32, 70, 155)","margin":"0px","padding-bottom":"15px"} }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("p", { style: {"font-weight":"700","font-size":"large","margin":"0px","color":"white"} }, "Successfully Submitted", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { style: {"margin-top":"10px"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("The block has been successfully submitted. You may either close the app or go back to the home page.")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearReservationSave())),
                      color: "primary",
                      style: {"margin-top":"15px","width":"-webkit-fill-available","--border-radius":"10px","margin-left":"0px","margin-right":"0px"},
                      href: "/home"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Home")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.isSubmitted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_DateSelectorComponent, _normalizeProps(_guardReactiveProps(_ctx.dateProps)), null, 16),
                _createVNode(_component_PropertyComponent, _normalizeProps(_guardReactiveProps(_ctx.isMinimal)), null, 16),
                _createVNode(_component_ion_card_header, { class: "ldx-section-start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, { position: "stacked" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Block Reason")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_list, { class: "ldx-block-form" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_textarea, {
                          modelValue: _ctx.ReasonText,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ReasonText) = $event)),
                          placeholder: "Enter text here..."
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (_ctx.isHasOwner())
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_checkbox, {
                                modelValue: _ctx.HasOwnerCheckBox,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.HasOwnerCheckBox) = $event))
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode("Designate as owner block")
                                ])),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_label, { "text-wrap": "" }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("Block will appear on housekeeping and arrival reports")
                            ])),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.store.reservationSelectedPropertyId != '' && _ctx.store.reservationSelectedPropertyId != null)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      style: {"margin-top":"15px","width":"-webkit-fill-available","--border-radius":"10px"},
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitButton())),
                      color: "primary"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Submit ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}