import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_card_header, { class: "ldx-section-start" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_title, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Guest Information")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_list, { class: "ldx-reservation-form" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, {
          lines: "none",
          onClick: _ctx.guestSelectModal,
          style: {"cursor":"pointer"}
        }, {
          default: _withCtx(() => [
            (_ctx.FirstName == '' || _ctx.LastName == '' || _ctx.Email == '' || !_ctx.validateEmail(_ctx.Email))
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 0,
                  style: {"color":"red"}
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("* Select Guest")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.FirstName != '' && _ctx.LastName != '' && _ctx.Email != '' && _ctx.validateEmail(_ctx.Email))
              ? (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.FirstName) + " " + _toDisplayString(_ctx.LastName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.FirstName == '' || _ctx.LastName == '' || _ctx.Email == '' || !_ctx.validateEmail(_ctx.Email))
              ? (_openBlock(), _createBlock(_component_ion_icon, {
                  key: 2,
                  icon: _ctx.searchOutline,
                  slot: "end"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true),
            (_ctx.FirstName != '' && _ctx.LastName != '' && _ctx.Email != '' && _ctx.validateEmail(_ctx.Email))
              ? (_openBlock(), _createBlock(_component_ion_icon, {
                  key: 3,
                  icon: _ctx.closeCircleOutline,
                  slot: "end"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_select, {
              "interface-options": _ctx.adultSelectOptions,
              interface: "alert",
              modelValue: _ctx.Adults,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.Adults) = $event)),
              placeholder: "1 Adult(s)"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(50, (n) => {
                  return _createVNode(_component_ion_select_option, {
                    key: n,
                    value: n
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(n) + " Adult(s)", 1)
                    ]),
                    _: 2
                  }, 1032, ["value"])
                }), 64))
              ]),
              _: 1
            }, 8, ["interface-options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_select, {
              "interface-options": _ctx.childrenSelectOptions,
              interface: "alert",
              modelValue: _ctx.Children,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.Children) = $event)),
              placeholder: "0 Children"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(51, (n) => {
                  return _createVNode(_component_ion_select_option, {
                    key: n,
                    value: n - 1
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(n - 1) + " Children", 1)
                    ]),
                    _: 2
                  }, 1032, ["value"])
                }), 64))
              ]),
              _: 1
            }, 8, ["interface-options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_select, {
              "interface-options": _ctx.petsSelectOptions,
              interface: "alert",
              modelValue: _ctx.Pets,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.Pets) = $event)),
              placeholder: "0 Pet(s)"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
                  return _createVNode(_component_ion_select_option, {
                    key: n,
                    value: n - 1
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(n - 1) + " Pet(s)", 1)
                    ]),
                    _: 2
                  }, 1032, ["value"])
                }), 64))
              ]),
              _: 1
            }, 8, ["interface-options", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createTextVNode(" " + _toDisplayString(_ctx.syncData()), 1)
  ]))
}