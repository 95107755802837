import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/no_image.png'
import _imports_1 from '@/assets/icons/bed-icon.png'
import _imports_2 from '@/assets/icons/bath-icon.png'
import _imports_3 from '@/assets/icons/guest-icon.png'
import _imports_4 from '@/assets/icons/pet-icon.png'
import _imports_5 from '@/assets/icons/nopet-icon.png'


const _hoisted_1 = { style: {"display":"flex","width":"100%"} }
const _hoisted_2 = {
  key: 0,
  style: {"display":"flex","align-items":"center","width":"100%"}
}
const _hoisted_3 = { style: {"font-weight":"700","font-size":"15px","margin":"0px","color":"black","padding-left":"5px"} }
const _hoisted_4 = { style: {"font-weight":"400"} }
const _hoisted_5 = { style: {"font-weight":"400"} }
const _hoisted_6 = {
  key: 1,
  style: {"display":"flex","align-items":"center","width":"100%"}
}
const _hoisted_7 = { class: "ldx-property-list" }
const _hoisted_8 = {
  key: 1,
  src: _imports_0
}
const _hoisted_9 = {
  key: 0,
  class: "ldx-prop-price",
  style: {"width":"100px","text-align":"center"}
}
const _hoisted_10 = { class: "ldx-quick-amenities" }
const _hoisted_11 = { class: "ldx-quick-amenity" }
const _hoisted_12 = { class: "ldx-quick-amenity" }
const _hoisted_13 = { class: "ldx-quick-amenity" }
const _hoisted_14 = { class: "ldx-quick-amenity" }
const _hoisted_15 = {
  key: 0,
  src: _imports_4
}
const _hoisted_16 = {
  key: 1,
  src: _imports_5
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: "Select Property",
    background: "rgb(239, 246, 255)"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isSearchOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, [
                _cache[4] || (_cache[4] = _createTextVNode(" Available: ")),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.availablePropertyList.length) + " Properties", 1),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _cache[6] || (_cache[6] = _createTextVNode(" Dates: ")),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatDate(_ctx.store.reservationStartDate)) + " - " + _toDisplayString(_ctx.formatDate(_ctx.store.reservationEndDate)), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isSearchOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_ion_searchbar, {
                placeholder: "Search Properties",
                debounce: 0,
                "show-clear-button": "never",
                onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchChange($event))),
                style: {"padding":"0px 5px"}
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isSearchOpen)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 2,
              class: "modal-header-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchButton()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { src: _ctx.searchCircle }, null, 8, ["src"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isSearchOpen)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 3,
              class: "modal-header-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchButton()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { src: _ctx.closeCircle }, null, 8, ["src"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_button, {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeOrder())),
          class: "modal-header-button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              style: _normalizeStyle(_ctx.iconStyle),
              icon: _ctx.filter
            }, null, 8, ["style", "icon"])
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePropertyList, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.searchPropertyMatch(index, item))
              ? (_openBlock(), _createBlock(_component_ion_card, {
                  mode: "ios",
                  class: "ldx-property-card",
                  onClick: ($event: any) => (_ctx.selectProperty(index, item)),
                  key: index,
                  value: item,
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    (item['thumbnail_url'] != null && item['thumbnail_url'] != '')
                      ? (_openBlock(), _createBlock(_component_ion_img, {
                          key: 0,
                          src: item['thumbnail_url']
                        }, null, 8, ["src"]))
                      : (_openBlock(), _createElementBlock("img", _hoisted_8)),
                    _createVNode(_component_ion_card_header, { style: {"text-align":"left"} }, {
                      default: _withCtx(() => [
                        (item['address']['short_name'] != null && item['address']['short_name'] != '')
                          ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.locationSharp }, null, 8, ["icon"]),
                                _createTextVNode(" " + _toDisplayString(item['address']['short_name']), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_card_title, {
                          color: "primary",
                          style: {"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item['name']), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        (!_ctx.isBlock)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.getDisplayMoney(_ctx.getPropertyFees(item['id']).total)), 1),
                              _cache[7] || (_cache[7] = _createTextVNode(" Total "))
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _cache[8] || (_cache[8] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(item['bedrooms']), 1)
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _cache[9] || (_cache[9] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(item['baths']), 1)
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _cache[10] || (_cache[10] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(item['max_guests']), 1)
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            (item['pets_allowed'])
                              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                              : (_openBlock(), _createElementBlock("img", _hoisted_16))
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    (!_ctx.isSelectedProperty(index, item))
                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                          key: 2,
                          style: {"max-width":"-webkit-fill-available","max-height":"40px","height":"auto","display":"flex","justify-content":"center","align-items":"center","padding":"0px"}
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createElementVNode("span", null, " Select Property ", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isSelectedProperty(index, item))
                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                          key: 3,
                          style: {"background":"#4c81f2","max-width":"-webkit-fill-available","max-height":"40px","height":"auto","display":"flex","justify-content":"center","align-items":"center","padding":"0px"}
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createElementVNode("span", null, " Property Selected ", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["onClick", "value"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ])
    ]),
    _: 1
  }))
}