import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/lodgix-white.svg'


const _hoisted_1 = { style: {"position":"fixed","width":"100%"} }
const _hoisted_2 = { style: {"margin-left":"30px","margin-right":"30px"} }
const _hoisted_3 = {
  class: "ldx-bottom-content",
  style: {"position":"fixed","bottom":"0px","padding-bottom":"35px","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { style: {"--background":"white"} }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ldx-login-logo ion-text-center" }, [
          _createElementVNode("img", {
            src: _imports_0,
            width: "188"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_card, {
            mode: "md",
            class: "ion-text-center ldx-welcome-card",
            style: {"max-width":"var(--max-width)","margin-left":"auto","margin-right":"auto"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("h1", null, "Welcome", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.login())),
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("LOGIN")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ion_label, {
          style: {"display":"flex","justify-content":"center","font-weight":"200","font-size":"medium"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendEmail()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("© " + _toDisplayString(_ctx.date) + " | support@lodgix.com | " + _toDisplayString(_ctx.appVersion), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}