import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/img/lodgix-icon-white.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.signedIn() && !_ctx.hideHeader)
        ? (_openBlock(), _createBlock(_component_ion_header, {
            key: 0,
            class: "ion-no-border",
            id: "ion-header"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, {
                color: "primary",
                mode: "md",
                id: "ion-toolbar"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, {
                    style: {"cursor":"pointer"},
                    slot: "start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_button)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title, { style: {"font-family":"'Montserrat', sans-serif","font-weight":"700","text-align":"center"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_thumbnail, {
                    style: {"cursor":"pointer"},
                    slot: "end",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goHome()))
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("img", { src: _imports_0 }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.$slots.header)
        ? (_openBlock(), _createBlock(_component_ion_header, {
            key: 1,
            style: {"box-shadow":"0px 1px 5px 0px rgba(194, 194, 194, 1)"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { style: {"--background":"white","padding":"0px !important"} }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header")
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, {
        mode: _ctx.removeMode ? '' : 'ios',
        fullscreen: true,
        style: _normalizeStyle([`--background: ${_ctx.backgroundColor}`])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["mode", "style"]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createBlock(_component_ion_footer, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { style: {"--background":"white","color":"black"} }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "footer")
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}