<template>
    <modal-base-layout ref="modalBaseLayoutRef" title="Control Panel" background="rgb(239, 246, 255)" v-if="data">
        <div v-if="statusDetails" :class="statusDetails.class" :style="statusDetails.style">
            <p :style="statusDetails.titleStyle">
                <component :is="statusDetails.icon" :style="statusDetails.imgStyle" />{{ statusDetails.title }}
            </p>
            <p>
                Reservation ID: #{{ data.reservationId }}
                <ion-icon
                    @click="AppFunctions.copyText(data.reservationId)"
                    style="margin-bottom: -2px; cursor: pointer"
                    :icon="copyOutline"
                    label="Copy Reservation ID"
                ></ion-icon>
                <br /><br />
                {{ data.bookingMethodName }} • {{ data.createdDate }}
            </p>
        </div>

        <!--
            Reservation Details
        -->
        <p style="margin: 10px; padding-top: 20px; font-size: x-large; color: #20469b; font-weight: 700">
            Reservation Details
        </p>
        <div
            class="ion-no-padding item"
            style="--background: transparent; margin: 10px; margin-top: 0px; margin-bottom: 0px"
        >
            <template v-for="(item, index) in data.properties" :key="index">
                <ion-item
                    class="ion-no-padding"
                    lines="none"
                    style="--background: transparent; width: 100%; border-bottom: none; margin: 0px"
                >
                    <ion-label v-if="index == 0">Property</ion-label>
                    <ion-label slot="end" style="text-align: end" v-if="item.room_id"
                        >{{ item.name }} - {{ item.room_id }}</ion-label
                    >
                    <ion-label slot="end" style="text-align: end" v-if="!item.room_id">{{ item.name }}</ion-label>
                </ion-item>
            </template>
        </div>
        <ion-item class="ion-no-padding item" lines="none">
            <ion-label>Adults</ion-label>
            <ion-label slot="end" style="text-align: end">{{ data.guests }}</ion-label>
        </ion-item>
        <ion-item class="ion-no-padding item" lines="none">
            <ion-label>Children</ion-label>
            <ion-label slot="end" style="text-align: end">{{ data.children }}</ion-label>
        </ion-item>
        <div
            class="ion-no-padding item"
            style="--background: transparent; margin: 10px; margin-top: 0px; margin-bottom: 0px"
        >
            <template v-for="(item, index) in data.properties" :key="index">
                <ion-item
                    class="ion-no-padding"
                    lines="none"
                    style="--background: transparent; width: 100%; border-bottom: none; margin: 0px"
                >
                    <ion-label v-if="index == 0">Pets</ion-label>
                    <ion-label slot="end" style="text-align: end" v-if="data.properties.length == 1">{{
                        item.number_of_pets
                    }}</ion-label>
                    <ion-label slot="end" style="text-align: end" v-if="data.properties.length > 1"
                        >{{ item.number_of_pets }} pets at {{ item.name }}</ion-label
                    >
                </ion-item>
            </template>
        </div>
        <ion-item class="ion-no-padding item" lines="none">
            <ion-label>Arrival</ion-label>
            <ion-label slot="end" style="text-align: end"
                >{{ formatDate(data.startDate) }}
                <span v-if="data.checkInTime">• {{ formatTime(data.checkInTime) }}</span></ion-label
            >
        </ion-item>
        <ion-item class="ion-no-padding item" lines="none">
            <ion-label>Departure</ion-label>
            <ion-label slot="end" style="text-align: end"
                >{{ formatDate(data.endDate) }}
                <span v-if="data.checkOutTime">• {{ formatTime(data.checkOutTime) }}</span></ion-label
            >
        </ion-item>
        <ion-item class="ion-no-padding item" style="border-bottom: none" lines="none">
            <ion-label>Invoice Notes</ion-label>
        </ion-item>
        <ion-item class="ion-no-padding item" style="border-bottom: none; align-items: start" lines="none">
            <ion-label :style="[tmpNotes ? '' : 'font-weight:bold; text-decoration:underline;']">{{
                tmpNotes || 'None'
            }}</ion-label>
            <ion-button
                @click="updateNotes()"
                style="max-width: 40px; max-height: 40px; height: 40px; width: 40px"
                slot="end"
                ><ion-icon :src="pencilOutline"></ion-icon
            ></ion-button>
        </ion-item>
        <div
            style="
                border-bottom-style: solid;
                border-bottom-color: rgb(200, 200, 200);
                border-bottom-width: 1px;
                padding-top: 2px;
            "
        ></div>

        <!--
            Guest Details
        -->
        <p style="margin: 10px; padding-top: 20px; font-size: x-large; color: #20469b; font-weight: 700">
            Guest Details
        </p>
        <ion-item class="ion-no-padding item" lines="none">
            <ion-label>Name</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.full_name }}</ion-label>
            <img
                slot="end"
                style="padding-right: 0px; margin-left: 10px"
                v-if="data.guest?.is_vip"
                src="@/assets/icons/vip.svg"
            />
            <img
                slot="end"
                style="padding-right: 0px; margin-left: 10px"
                v-if="data.guest?.is_blocklist"
                src="@/assets/icons/blocklist.svg"
            />
        </ion-item>
        <ion-item v-if="data.guest?.phone" class="ion-no-padding item" lines="none">
            <ion-label>Email</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.email }}</ion-label>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openEmail(data.guest?.email)"
                :icon="mailOutline"
            >
            </ion-icon>
        </ion-item>
        <ion-item v-else class="ion-no-padding item" style="border-bottom: none" lines="none">
            <ion-label>Email</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.email }}</ion-label>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openEmail(data.guest?.email)"
                :icon="mailOutline"
            >
            </ion-icon>
        </ion-item>
        <ion-item v-if="data.guest?.phone" class="ion-no-padding item" style="border-bottom: none" lines="none">
            <ion-label>Phone</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.phone }}</ion-label>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openPhone(data.guest?.phone)"
                :icon="callOutline"
            >
            </ion-icon>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openText(data.guest?.phone)"
                :icon="chatboxEllipsesOutline"
            >
            </ion-icon>
        </ion-item>
        <div
            style="border-bottom-style: solid; border-bottom-color: rgb(200, 200, 200); border-bottom-width: 1px"
        ></div>

        <!--
            Payment Summary
        -->
        <p style="margin: 10px; padding-top: 20px; font-size: x-large; color: #20469b; font-weight: 700">
            Payment Summary
        </p>
        <ion-item class="ion-no-padding item" lines="none">
            <ion-label>Total</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.total) }}</ion-label>
        </ion-item>
        <ion-item v-if="data.confirmationAmount >= 0" class="ion-no-padding item" lines="none">
            <ion-label>Confirmation Amount</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.confirmationAmount) }}</ion-label>
        </ion-item>
        <ion-item class="ion-no-padding item" style="border-bottom: none" lines="none">
            <ion-label>Total with Deposits</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.totalDeposits) }}</ion-label>
        </ion-item>
        <div
            style="border-bottom-style: solid; border-bottom-color: rgb(200, 200, 200); border-bottom-width: 1px"
        ></div>
        <ion-item class="ion-no-padding item" style="border-bottom: none" lines="none">
            <ion-label>Paid</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.paid) }}</ion-label>
        </ion-item>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="modalBaseLayoutRef.cancel()"
                            >Close</ion-button
                        >
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="createNewReservation"
                            >Add Reservation</ion-button
                        >
                    </ion-col>
                </ion-row>
                <ion-row v-if="data.conversationId">
                    <ion-col>
                        <ion-button class="modal-footer-button" @click="openConversation(data.conversationId)"
                            >View Conversation</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import {
        arrowBackOutline,
        mailOutline,
        callOutline,
        chatboxEllipsesOutline,
        person,
        card,
        clipboard,
        documentText,
        copyOutline,
        chatbubblesOutline,
        pencilOutline,
    } from 'ionicons/icons';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { controlPanelStatusMap } from '@/utils/constants';
    import AppAPI from '@/services/AppAPI';
    import { successToast, errorToast } from '@/services/Toast';
    import ModalController from '@/services/ModalController';

    export default defineComponent({
        components: {},
        props: {
            invoiceNumber: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const store = useStore();
            let todayDate = format(new Date(), 'yyyy-MM-dd');
            const modalBaseLayoutRef = ref();
            const tmpNotes = ref();
            const data = ref({} as any);

            onMounted(async () => {
                await fetchData();
            });

            const fetchData = async () => {
                const reservation = await AppAPI.getReservationByInv(props.invoiceNumber);
                const createOnDate = format(new Date(reservation.data.created), 'MM/dd/yyyy');
                data.value = {
                    id: reservation.data.guest.id,
                    startDate: reservation.data.from_date,
                    endDate: reservation.data.to_date,
                    properties: reservation.data.properties,
                    status: reservation.data.state,
                    reservationId: props.invoiceNumber,
                    guest: reservation.data.guest,
                    stayType: reservation.data.stay_type,
                    createdDate: createOnDate,
                    guests: reservation.data.guests,
                    children: reservation.data.children,
                    checkInTime: reservation.data.check_in,
                    checkOutTime: reservation.data.check_out,
                    total: reservation.data.total,
                    confirmationAmount: reservation.data.confirmation_amount,
                    totalDeposits: reservation.data.total_with_deposits,
                    paid: reservation.data.paid,
                    substate: reservation.data.substate,
                    bookingMethodName: reservation.data.booking_method_name,
                    conversationId: reservation.data?.conversation_id || '',
                    notes: reservation.data.notes || '',
                };
                tmpNotes.value = data.value.notes;
            };

            const statusDetails = computed(() => {
                const map: { [key: string]: any } = controlPanelStatusMap(data.value);
                return map[data.value.status] || null;
            });

            const formatDate = (date: string) => {
                if (!date) {
                    return '';
                }
                let tmpDateArray = date.split('-');
                if (tmpDateArray.length <= 1) {
                    return date;
                }
                const month = tmpDateArray[1];
                const day = tmpDateArray[2];
                const year = tmpDateArray[0];

                let fromDateString = month + '/' + day + '/' + year;

                return fromDateString;
            };

            const formatTime = (time: string) => {
                try {
                    time = '2021-01-01T' + time;
                    time = format(new Date(time), 'h:mm a');
                } catch (e) {
                    time = '';
                }
                return time;
            };

            const formatPrice = (value: number) => {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: store.userCurrency,
                });
                return format.format(value);
            };

            const createNewReservation = () => {
                store.changeReservationFirstName(data.value.guest?.first_name);
                store.changeReservationLastName(data.value.guest?.last_name);
                store.changeReservationEmail(data.value.guest?.email);
                store.changeReservationGuestId(data.value.guest?.id);
                store.changeActivePage('/createReservation');
                modalBaseLayoutRef.value.ionRouter.replace('/createreservation');
                modalBaseLayoutRef.value.cancel();
            };

            const openConversation = (conversationId: string) => {
                AppFunctions.openChat(conversationId);
                modalBaseLayoutRef.value.cancel();
            };

            const updateNotes = async () => {
                const result = await ModalController.editInvoiceNotesModal(data.value.notes);
                if (result) {
                    console.log('updatedNote', result.notes);

                    if (result.notes == data.value.notes) {
                        return;
                    }

                    try {
                        const response = await AppAPI.updateInvoiceNotes(props.invoiceNumber, result.notes);
                        if (response) {
                            successToast('Invoice notes saved');
                        }
                    } catch (e) {
                        console.error(e);
                        errorToast('Error saving invoice notes');
                    } finally {
                        await fetchData();
                    }
                }
            };

            return {
                arrowBackOutline,
                mailOutline,
                callOutline,
                chatboxEllipsesOutline,
                person,
                card,
                clipboard,
                todayDate,
                documentText,
                copyOutline,
                chatbubblesOutline,
                confirm,
                formatDate,
                formatTime,
                formatPrice,
                createNewReservation,
                openConversation,
                modalBaseLayoutRef,
                AppFunctions,
                statusDetails,
                tmpNotes,
                updateNotes,
                data,
                ModalController,
                pencilOutline,
            };
        },
    });
</script>

<style scoped>
    .statusCard {
        border-radius: 10px;
        margin: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .item {
        --background: transparent;
        margin: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        border-bottom-style: solid;
        border-bottom-color: rgb(220, 220, 220);
        border-bottom-width: 1px;
    }
</style>
