<template>
    <base-layout page-title="Work Orders" lightBlueBackground>
        <template #header>
            <div style="display: flex; align-items: center" class="ion-nowrap">
                <ion-button
                    v-if="store.workOrderPermissions.can_add"
                    class="modal-header-button"
                    color="primary"
                    @click="createWorkOrder()"
                    ><ion-icon :src="add"></ion-icon
                ></ion-button>
                <ion-button class="select-button">
                    <ion-select v-model="showClosed" placeholder="Show Closed">
                        <ion-select-option :value="false">Hide Closed</ion-select-option>
                        <ion-select-option :value="true">Show Closed</ion-select-option>
                    </ion-select>
                </ion-button>
            </div>
        </template>

        <div>
            <template v-for="(item, index) in store.WorkOrderList" :key="index">
                <ion-card @click="openModal(item.id.toString())" style="cursor: pointer">
                    <ion-card-content class="ion-no-padding" style="padding: 10px">
                        <div style="display: flex; flex-direction: column; align-items: left; width: 100%">
                            <div
                                style="display: flex; align-items: center; justify-content: space-between; width: 100%"
                            >
                                <div>
                                    <div
                                        :style="[
                                            !item.is_viewed ? 'font-weight:bolder;' : 'font-weight:normal;',
                                            'font-size:18px;',
                                        ]"
                                    >
                                        {{ item.number }}: {{ item.property_name }}
                                    </div>
                                    <div
                                        style="font-size: 10px; color: #a9a9a9; font-weight: normal"
                                        v-if="item.date_reported"
                                    >
                                        Reported by <span style="font-weight: bolder">{{ item.reporter_name }}</span> on
                                        <span style="font-weight: bolder">{{ formatDate(item.date_reported) }}</span>
                                    </div>
                                </div>
                                <img
                                    v-if="item.assigned_to !== '-'"
                                    style="border-radius: 50%; width: 30px; height: 30px"
                                    :src="generateAvatarFromName(item.assigned_to)"
                                    :alt="item.assigned_to"
                                />
                            </div>
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                "
                                v-if="item.description"
                            >
                                <div
                                    style="
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-size: 12px;
                                    "
                                    :style="[item.description ? '' : 'color:#a9a9a9']"
                                >
                                    {{ item.description ? item.description : 'No Description' }}
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; margin-left: -5px">
                            <ion-chip style="background: #f5f5f5; color: #000; margin: 5px">
                                <ion-icon
                                    v-if="statusIcon(item.status_name.toUpperCase()).icon"
                                    :icon="statusIcon(item.status_name.toUpperCase()).icon"
                                    :style="{ color: statusIcon(item.status_name.toUpperCase()).color }"
                                ></ion-icon>
                                <ion-label style="font-size: 10px">{{ item.status_name.toUpperCase() }}</ion-label>
                            </ion-chip>
                            <ion-chip style="background: #f5f5f5; color: #000; margin: 5px">
                                <ion-icon
                                    v-if="priorityIcon(item.priority_name.toUpperCase()).icon"
                                    :icon="priorityIcon(item.priority_name.toUpperCase()).icon"
                                    :style="{ color: priorityIcon(item.priority_name.toUpperCase()).color }"
                                ></ion-icon>
                                <ion-label style="font-size: 10px">{{ item.priority_name.toUpperCase() }}</ion-label>
                            </ion-chip>

                            <ion-chip
                                style="background: #f5f5f5; color: #000; margin: 5px"
                                v-if="item.damage_notice_source_id"
                            >
                                <DamageNoticeIcon style="fill: #0d47a1; width: 20px; height: 20px" />
                            </ion-chip>
                        </div>
                    </ion-card-content>
                </ion-card>
            </template>
            <div style="width: 100%; display: flex; justify-content: center; padding: 10px; padding-bottom: 20px">
                <ion-button
                    class="filter-button"
                    color="primary"
                    v-if="store.WorkOrderList.length < store.WorkOrderListTotal"
                    @click="loadMore()"
                    >View More</ion-button
                >
            </div>
        </div>

        <template #footer>
            <div style="display: flex; justify-content: center; align-items: center">
                <ion-label style="font-size: larger">Total: {{ store.WorkOrderListTotal }} Work Orders</ion-label>
            </div>
        </template>
    </base-layout>
</template>

<script lang="ts">
    import AppAPI from '@/services/AppAPI';
    import ModalController from '@/services/ModalController';
    import { useStore } from '@/store/store';
    import { useIonRouter } from '@ionic/vue';
    import { format } from 'date-fns';
    import {
        alertCircle,
        closeCircleOutline,
        add,
        checkmarkCircleOutline,
        ellipseOutline,
        arrowUpCircleOutline,
        alertCircleOutline,
        arrowDownCircleOutline,
        chevronUp,
        documentTextOutline,
    } from 'ionicons/icons';
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import DamageNoticeIcon from '@/assets/icons/damageNoticeIcon.vue';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { workOrderAccess } from '@/types/accessGroups';
    import { errorToast } from '@/services/Toast';
    import { workOrderPriorityColors, workOrderStatusColors } from '@/utils/constants';
    import router from '@/router';

    export default defineComponent({
        components: {
            DamageNoticeIcon,
        },

        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const showClosed = ref(false);
            const listLimit = ref(20);

            const statusIcon = (status: any) => {
                if (status === 'OPEN') {
                    return {
                        icon: null,
                        color: workOrderStatusColors.OPEN,
                    };
                } else if (status === 'CLOSED') {
                    return {
                        icon: checkmarkCircleOutline,
                        color: workOrderStatusColors.CLOSED,
                    };
                } else if (status === 'CANCELLED') {
                    return {
                        icon: closeCircleOutline,
                        color: workOrderStatusColors.CANCELLED,
                    };
                } else if (status === 'ESCALATED') {
                    return {
                        icon: arrowUpCircleOutline,
                        color: workOrderStatusColors.ESCALATED,
                    };
                } else {
                    return {
                        icon: ellipseOutline,
                        color: workOrderStatusColors.DEFAULT,
                    };
                }
            };

            const priorityIcon = (priority: any) => {
                if (priority == 'CRITICAL') {
                    return {
                        icon: alertCircle,
                        color: workOrderPriorityColors.CRITICAL,
                    };
                } else if (priority == 'URGENT') {
                    return {
                        icon: alertCircleOutline,
                        color: workOrderPriorityColors.URGENT,
                    };
                } else if (priority === 'HIGH') {
                    return {
                        icon: arrowUpCircleOutline,
                        color: workOrderPriorityColors.HIGH,
                    };
                } else if (priority === 'MEDIUM') {
                    return {
                        icon: null,
                        color: workOrderPriorityColors.MEDIUM,
                    };
                } else if (priority === 'LOW') {
                    return {
                        icon: arrowDownCircleOutline,
                        color: workOrderPriorityColors.LOW,
                    };
                } else {
                    return {
                        icon: null,
                        color: workOrderPriorityColors.DEFAULT,
                    };
                }
            };

            onMounted(async () => {
                if (!workOrderAccess()) {
                    ionRouter.replace('/');
                    errorToast('You do not have permission to access Work Orders');
                    return;
                }

                const loadingService = await showLoading('Loading...');

                try {
                    let response = await AppAPI.getWorkOrderJobTypes();
                    store.changeWorkOrderJobTypes(response.data);
                    response = await AppAPI.getWorkOrderVendors();
                    store.changeWorkOrderVendors(response.data);
                    response = await AppAPI.getWorkOrderExpenseAccounts();
                    store.changeWorkOrderExpenseAccounts(response.data);
                    response = await AppAPI.getWorkOrderFormOptions();
                    store.changeWorkOrderFormOptions(response.data);
                    response = await AppAPI.getWorkOrderFormProperties();
                    store.changeWorkOrderFormProperties(response.data);
                    response = await AppAPI.getWorkOrderTeamMembers();
                    store.changeWorkOrderTeamMembers(response.data);

                    const data = await AppAPI.getWorkOrders(showClosed.value, 0, listLimit.value);
                    store.changeWorkOrderList(data.data.data);
                    store.changeWorkOrderListTotal(data.data.total_records);

                    const metadata = await AppAPI.getWorkOrderMetadata();
                    store.changeWorkOrderPermissions(metadata.data);

                    hideLoading(loadingService);
                    if (router.currentRoute.value.query.id?.toString()) {
                        openModal(router.currentRoute.value.query.id.toString());
                    }
                } catch (e) {
                    console.log('WorkOrderPage', e);
                    hideLoading(loadingService);
                }
            });

            const formatDate = (date: string) => {
                if (!date) {
                    return '';
                }
                return format(new Date(date), 'MM/dd/yy');
            };

            watch(showClosed, async (value) => {
                const data = await AppAPI.getWorkOrders(value, 0, listLimit.value);
                store.WorkOrderList = data.data.data;
                store.WorkOrderListTotal = data.data.total_records;
            });

            const loadMore = async () => {
                const data = await AppAPI.getWorkOrders(showClosed.value, store.WorkOrderList.length, listLimit.value);
                store.WorkOrderList = [...store.WorkOrderList, ...data.data.data];
                store.WorkOrderListTotal = data.data.total_records;
            };

            const openModal = async (id: string) => {
                await ModalController.workOrderDetailsModal(id);
                const loadingService = await showLoading('Loading...');
                try {
                    await AppAPI.markWorkOrderAsRead(id);
                    const data = await AppAPI.getWorkOrders(showClosed.value, 0, listLimit.value);
                    store.changeWorkOrderList(data.data.data);
                    store.changeWorkOrderListTotal(data.data.total_records);
                    hideLoading(loadingService);
                } catch (e) {
                    hideLoading(loadingService);
                    console.log('WorkOrderPage', e);
                }
            };

            const generateAvatarFromName = (fullName: string) => {
                const profileImage = document.createElement('canvas');
                const ctx = profileImage.getContext('2d');
                if (!ctx) {
                    return '';
                }
                profileImage.width = 64;
                profileImage.height = 64;
                ctx.fillStyle = getAvatarColor(fullName);
                ctx.fillRect(0, 0, profileImage.width, profileImage.height);
                const fontSize = 25;
                ctx.font = `bold ${fontSize}px arial`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = '#ffffff';
                ctx.fillText(getInitialsFromName(fullName), profileImage.width / 2, profileImage.height / 2 + 2);
                return profileImage.toDataURL();
            };

            const getInitialsFromName = (fullName: string) => {
                const names = fullName.split(' ');
                let initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            };

            const getAvatarColor = (fullName: string) => {
                const hRange = [0, 360];
                const sRange = [50, 75];
                const lRange = [25, 60];
                const getHashOfString = (str: string) => {
                    let hash = 0;
                    try {
                        for (let i = 0; i < str?.length; i++) {
                            hash = str.charCodeAt(i) + ((hash << 5) - hash);
                        }
                        hash = Math.abs(hash);
                    } catch (e) {
                        console.log('avatar color', e);
                    }
                    return hash;
                };
                const normalizeHash = (hash: number, min: number, max: number) => {
                    return Math.floor((hash % (max - min)) + min);
                };
                const generateHSL = (name: string) => {
                    const hash = getHashOfString(name);
                    const h = normalizeHash(hash, hRange[0], hRange[1]);
                    const s = normalizeHash(hash, sRange[0], sRange[1]);
                    const l = normalizeHash(hash, lRange[0], lRange[1]);
                    return [h, s, l];
                };
                const HSLtoString = (hsl: any) => {
                    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
                };
                return HSLtoString(generateHSL(fullName));
            };

            const createWorkOrder = async () => {
                await ModalController.workOrderDetailsModal('');
                const data = await AppAPI.getWorkOrders(showClosed.value, 0, listLimit.value);
                store.changeWorkOrderList(data.data.data);
                store.changeWorkOrderListTotal(data.data.total_records);
            };

            return {
                ionRouter,
                store,
                showClosed,
                listLimit,
                alertCircle,
                add,
                closeCircleOutline,
                workOrderPriorityColors,
                workOrderStatusColors,
                chevronUp,
                documentTextOutline,
                createWorkOrder,
                generateAvatarFromName,
                formatDate,
                loadMore,
                openModal,
                statusIcon,
                priorityIcon,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        margin: 10px;
    }
</style>
