import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    ref: "modalBaseLayoutRef",
    title: "Widget Settings"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { style: {"font-size":"14px"} }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("span", { style: {"font-weight":"bold"} }, "Notice", -1),
                      _createTextVNode(": These settings are saved on device and are not synced between your devices on your account.")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.modalBaseLayoutRef.cancel()))
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ion_list, { lines: "none" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.inquiriesWidgetValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inquiriesWidgetValue) = $event))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Inquiries Widget")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.pendingWidgetValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pendingWidgetValue) = $event))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Pending Widget")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.arrivalsWidgetValue,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.arrivalsWidgetValue) = $event))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Arrivals Widget")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.departuresWidgetValue,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.departuresWidgetValue) = $event))
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Departures Widget")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.occupancyWidgetValue,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.occupancyWidgetValue) = $event))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Occupancy Widget")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}