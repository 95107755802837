import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/notifications-icon.svg'
import _imports_1 from '@/assets/icons/checkmark-icon.svg'


const _hoisted_1 = {
  key: 0,
  style: {"width":"100%","height":"100%","overflow":"hidden","position":"fixed"},
  class: "bell-label",
  ref: "bellLabel"
}
const _hoisted_2 = { style: {"display":"flex","justify-content":"center","padding":"0px","margin":"0px"} }
const _hoisted_3 = { style: {"margin-top":"2px"} }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    "page-title": "Notifications",
    lightBlueBackground: ""
  }, {
    default: _withCtx(() => [
      (_ctx.store.PushNotificationList.length <= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"width":"70%","height":"35%","margin":"15%","margin-bottom":"0px"} }, [
              _createElementVNode("img", {
                style: {"width":"100%","height":"100%"},
                src: _imports_0
              })
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_label, { style: {"font-size":"x-large","font-weight":"800"} }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("No Notifications")
                ])),
                _: 1
              })
            ])
          ], 512))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ion_list, {
          ref: "notificationList",
          style: {"background":"transparent","margin":"auto"},
          lines: "none"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.pushNotificationList, (item, index) => {
              return (_openBlock(), _createBlock(_component_ion_item_sliding, {
                key: index,
                onIonSwipe: ($event: any) => (_ctx.markAsRead($event, item.id.toString(), index))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    ref_for: true,
                    ref: "ionItem",
                    style: {"--background":"transparent","margin-left":"-23px","margin-right":"-23px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        style: {"margin":"15px","margin-bottom":"8px","margin-top":"8px","border-radius":"10px","background":"white","border-style":"solid","border-width":"1px","border-color":"white","-webkit-box-shadow":"0px 0px 11px 2px rgba(224, 224, 224, 1)","-moz-box-shadow":"0px 0px 11px 2px rgba(224, 224, 224, 1)","box-shadow":"0px 0px 11px 2px rgba(224, 224, 224, 1)","width":"100%","cursor":"pointer"},
                        onClick: ($event: any) => (_ctx.openNotification(item))
                      }, [
                        _createVNode(_component_ion_grid, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, { style: {"align-items":"center"} }, {
                                  default: _withCtx(() => [
                                    (item.subject.length < 80)
                                      ? (_openBlock(), _createBlock(_component_ion_label, {
                                          key: 0,
                                          style: {"font-size":"18px","font-weight":"800","color":"black","align-items":"center"},
                                          "text-wrap": ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.subject), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    (item.subject.length >= 80)
                                      ? (_openBlock(), _createBlock(_component_ion_label, {
                                          key: 1,
                                          style: {"font-size":"18px","font-weight":"800"},
                                          "text-wrap": ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.subject.slice(0, 80)) + "...", 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_label, {
                                      style: {"font-size":"small","font-weight":"500"},
                                      "text-wrap": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatDate(item.date)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    (item.body.length < 110)
                                      ? (_openBlock(), _createBlock(_component_ion_label, {
                                          key: 0,
                                          style: {"font-size":"medium","font-weight":"500"},
                                          "text-wrap": ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.body), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    (item.body.length >= 110)
                                      ? (_openBlock(), _createBlock(_component_ion_label, {
                                          key: 1,
                                          style: {"font-size":"medium","font-weight":"500"},
                                          "text-wrap": ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.body.slice(0, 110)) + "...", 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ], 8, _hoisted_4)
                    ]),
                    _: 2
                  }, 1536),
                  _createVNode(_component_ion_item_options, { side: "end" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        ref_for: true,
                        ref: "checkmark",
                        class: "checkmark"
                      }, _cache[3] || (_cache[3] = [
                        _createElementVNode("img", {
                          style: {"width":"75px","height":"75px"},
                          src: _imports_1
                        }, null, -1)
                      ]), 512),
                      _createVNode(_component_ion_item_option, {
                        color: "transpartent",
                        expandable: "true",
                        side: "end",
                        onClick: ($event: any) => (_ctx.markAsRead('', item.id.toString(), index))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            ref_for: true,
                            ref: "icon",
                            style: {"width":"50px","height":"50px"},
                            icon: _ctx.checkmarkOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onIonSwipe"]))
            }), 128))
          ]),
          _: 1
        }, 512)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"padding-bottom":"100px"} }, null, -1)),
      _createVNode(_component_ion_fab, {
        slot: "fixed",
        vertical: "bottom",
        horizontal: "end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.chevronUp }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_fab_list, { side: "top" }, {
            default: _withCtx(() => [
              (_ctx.getPlatform() != 'web')
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 0,
                    title: "Help",
                    onClick: _ctx.openSupport
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.document }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_fab_button, {
                title: "Mark All As Read",
                onClick: _ctx.markAllRead
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_fab_button, {
                title: "Refresh",
                onClick: _ctx.handleRefresh
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.reload }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.getPlatform() != 'web')
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 1,
                    title: "Device Settings",
                    onClick: _ctx.openDeviceSettings
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.settings }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.getPlatform() != 'web')
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 2,
                    title: "Push Notification Settings",
                    onClick: _ctx.openDeviceModal
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.notifications }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_fab_button, {
                title: "Information",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
                        _ctx.basicAlert(
                            'Information',
                            '',
                            'This page displays all unread notifications. To view read notifications and more, please visit the Lodgix.com website.'
                        )
                    ))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.information }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}