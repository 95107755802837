import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "guest-info" }
const _hoisted_2 = { class: "address" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { style: {"font-size":"18px","font-weight":"700"} }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper_container = _resolveComponent("swiper-container")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: `Guest Details`,
    background: "#f4f5f8"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Back")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.guest)
        ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_header, { class: "header-container" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.title?.name || '') + " " + _toDisplayString(_ctx.guest.first_name) + " " + _toDisplayString(_ctx.guest.last_name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.email), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, { lines: "full" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("Address")
                            ])),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_2, [
                            (_ctx.guest.address.address1)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.guest.address.address1), 1))
                              : _createCommentVNode("", true),
                            (_ctx.guest.address.address2)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.guest.address.address2), 1))
                              : _createCommentVNode("", true),
                            (_ctx.guest.address.city || _ctx.guest.address.state?.code || _ctx.guest.address.zip)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.guest.address.city) + ", " + _toDisplayString(_ctx.guest.address.state?.code || '') + " " + _toDisplayString(_ctx.guest.address.zip), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("p", null, _toDisplayString(_ctx.guest.address.country?.name || ''), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      (_ctx.guest.date_of_birth)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("Date of Birth")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.dob), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.status_id)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("Guest's Status")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.statusLabel), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.mobile_phone)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode("Phone (mobile)")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.guest.mobile_phone), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.phone)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("Phone (home)")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.guest.phone), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.work_phone)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 4 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("Phone (work)")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.guest.work_phone), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.work_phone_ext)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 5 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode("Ext")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.guest.work_phone_ext), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.fax)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 6 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode("Fax")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.guest.fax), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.guest.note)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 7 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                  _createTextVNode("Notes")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.guest.note), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "note" }, "Note: Guest details can only be edited on Lodgix.com.", -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.invoices && _ctx.invoices.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_header, { style: {"display":"flex","flex-direction":"row","justify-content":"space-between","align-items":"center"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, null, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Recent Invoices")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewAllInvoices()))
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("View All")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_swiper_container, {
                    "slides-per-view": 1,
                    "centered-slides": true,
                    navigation: true,
                    pagination: true,
                    style: {"border-radius":"10px !important"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice) => {
                        return (_openBlock(), _createBlock(_component_swiper_slide, {
                          key: invoice,
                          style: {"border-radius":"10px !important","padding":"0 40px"}
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", _hoisted_6, "Invoice #" + _toDisplayString(invoice.id), 1),
                            _createVNode(_component_ion_list, { lines: "full" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                                        _createTextVNode("Property")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(invoice.properties[0].name || 'N/A'), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                                        _createTextVNode("From Date")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatDate(invoice.from_date)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode("To Date")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatDate(invoice.to_date)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                                        _createTextVNode("Total Amount")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatPrice(invoice.total)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_ctx.invoices && _ctx.invoices.length <= 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("No Invoices for Guest")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}