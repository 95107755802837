import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './../assets/img/lodgix-icon-white.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_LoginPage = _resolveComponent("LoginPage")!
  const _component_HousekeeperComponent = _resolveComponent("HousekeeperComponent")!
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { mode: "ios" }, {
    default: _withCtx(() => [
      (_ctx.signedIn())
        ? (_openBlock(), _createBlock(_component_ion_header, {
            key: 0,
            class: "ion-no-border"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, {
                color: "primary",
                mode: "md"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_button)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title),
                  _createVNode(_component_ion_thumbnail, {
                    style: {"cursor":"pointer"},
                    slot: "end",
                    href: "/"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("img", { src: _imports_0 }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.signedIn())
        ? (_openBlock(), _createBlock(_component_LoginPage, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.signedIn())
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 2,
            class: "main-content",
            style: {"display":"flex","justify-content":"center","--overflow":"hidden"}
          }, {
            default: _withCtx(() => [
              (_ctx.housekeeperGroup())
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_HousekeeperComponent)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_MainMenu)
                  ]))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}