<template>
    <base-layout page-title="View Reservations" lightBlueBackground removeMode>
        <template #header v-if="allowFilter">
            <div style="display: flex; align-items: center; padding: 2px 0">
                <ion-searchbar
                    :placeholder="'Search ' + (filters.type ? 'Blocks' : 'Reservations')"
                    :debounce="250"
                    :value="searchValue"
                    @ionInput="search($event)"
                    style="padding: 0px 5px"
                ></ion-searchbar>
                <ion-button
                    color="primary"
                    style="max-width: fit-content; aspect-ratio: 1/1; padding: 2px"
                    @click="updateFilters()"
                    ><ion-icon :icon="filter"
                /></ion-button>
            </div>
        </template>

        <div v-if="reservationList.length > 0">
            <template v-for="(item, index) in reservationList" :key="index">
                <div lines="none" style="max-width: 500px; margin: auto">
                    <!--
                        Reservation Card
                    -->
                    <div v-if="item.state != 'BLOCKED'">
                        <ion-card
                            mode="ios"
                            @click="ModalController.controlPanelModal(item.id)"
                            style="margin: 10px; margin-bottom: 15px; margin-top: 15px; cursor: pointer"
                        >
                            <ion-card-content style="padding: 0px; margin: 5px">
                                <ion-item style="height: 35px" lines="none" class="ion-no-padding">
                                    <ion-label
                                        text-wrap
                                        style="font-weight: 700; padding-left: 20px; font-size: medium"
                                        >{{ item.guest.full_name }}</ion-label
                                    >

                                    <div
                                        v-if="item.state == 'PAID' && item.substate == 'PAID'"
                                        data-v-4262366e=""
                                        title="Paid In Full"
                                        class="reservation-status-marker mr-2"
                                        style="background: rgb(0, 153, 0)"
                                        slot="end"
                                    ></div>
                                    <div
                                        v-if="item.state == 'PAID' && item.substate != 'PAID'"
                                        data-v-4262366e=""
                                        title="Paid"
                                        class="reservation-status-marker mr-2"
                                        style="background: rgb(0, 153, 0)"
                                        slot="end"
                                    ></div>

                                    <div
                                        v-if="item.state == 'PAIDDEPOSIT'"
                                        data-v-4262366e=""
                                        title="Confirmed"
                                        class="reservation-status-marker mr-2"
                                        style="
                                            background: linear-gradient(
                                                transparent 0%,
                                                transparent 50%,
                                                rgb(255, 170, 0) 50%,
                                                rgb(255, 170, 0) 100%
                                            );
                                            border: 2px solid rgb(255, 170, 0);
                                        "
                                        slot="end"
                                    ></div>
                                    <div
                                        v-if="item.state == 'NOTPAID'"
                                        data-v-4262366e=""
                                        title="Unconfirmed"
                                        class="reservation-status-marker mr-2"
                                        style="border: 2px solid rgb(204, 0, 0)"
                                        slot="end"
                                    ></div>
                                    <div
                                        v-if="item.state == 'CANCELLED'"
                                        data-v-4262366e=""
                                        title="Cancelled"
                                        class="reservation-status-marker mr-2"
                                        style="background: rgb(158, 158, 158); border-radius: 0px"
                                        slot="end"
                                    ></div>
                                </ion-item>

                                <ion-item lines="none">
                                    <div slot="start" style="margin-inline-end: 0px" v-if="item.properties.length > 0">
                                        <img
                                            v-if="!item.properties[0].thumbnail_url && item.properties.length == 1"
                                            style="width: 100px; height: 40px"
                                            src="@/assets/icons/home-outline.svg"
                                        />
                                        <img
                                            v-if="item.properties[0].thumbnail_url && item.properties.length == 1"
                                            style="width: 100px; border-radius: 10px"
                                            :src="item.properties[0].thumbnail_url"
                                        />
                                        <img
                                            v-if="item.properties.length > 1"
                                            style="width: 100px; border-radius: 10px"
                                            src="@/assets/icons/multi-property-icon.png"
                                        />
                                    </div>
                                    <div slot="start" v-if="item.properties.length <= 0">
                                        <img style="width: 100px; height: 40px" src="@/assets/icons/home-outline.svg" />
                                    </div>
                                    <ion-list slot="end" style="width: 100%; margin-bottom: 10px">
                                        <div
                                            style="font-weight: 700; font-size: small; padding-left: 15px"
                                            text-wrap
                                            v-for="(list, index) in item.properties"
                                            :key="index"
                                        >
                                            {{ list.name }}
                                        </div>
                                        <div v-if="item.state == 'PAIDDEPOSIT'">
                                            <p style="font-weight: 500; font-size: small; padding-left: 15px" text-wrap>
                                                {{ item.booking_method_name }} ·
                                                <span style="color: rgb(255, 170, 0)">Confirmed</span>
                                            </p>
                                        </div>
                                        <div v-if="item.state == 'PAID' && item.substate == 'PAID'">
                                            <p style="font-weight: 500; font-size: small; padding-left: 15px" text-wrap>
                                                {{ item.booking_method_name }} ·
                                                <span style="color: rgb(0, 153, 0)">Paid In Full</span>
                                            </p>
                                        </div>
                                        <div v-if="item.state == 'PAID' && item.substate != 'PAID'">
                                            <p style="font-weight: 500; font-size: small; padding-left: 15px" text-wrap>
                                                {{ item.booking_method_name }} ·
                                                <span style="color: rgb(0, 153, 0)">Paid</span>
                                            </p>
                                        </div>
                                        <div v-if="item.state == 'NOTPAID'">
                                            <p style="font-weight: 500; font-size: small; padding-left: 15px" text-wrap>
                                                {{ item.booking_method_name }} ·
                                                <span style="color: rgb(204, 0, 0)">Unconfirmed</span>
                                            </p>
                                        </div>
                                        <div v-if="item.state == 'CANCELLED'">
                                            <p style="font-weight: 500; font-size: small; padding-left: 15px" text-wrap>
                                                {{ item.booking_method_name }} ·
                                                <span style="color: rgb(113, 113, 113)">Cancelled</span>
                                            </p>
                                        </div>
                                        <div style="padding-left: 2px" class="ldx-quick-amenities">
                                            <div class="ldx-quick-amenity">
                                                <img src="@/assets/icons/calendar.svg" />
                                                <span>{{ formatDate(item.from_date) }}</span>
                                            </div>
                                            <div class="ldx-quick-amenity">
                                                <img src="@/assets/icons/moon.svg" />
                                                <span>{{ item.nights }}</span>
                                            </div>
                                            <div class="ldx-quick-amenity">
                                                <img src="@/assets/icons/guest-icon.png" />
                                                <span>{{ item.guests + item.children }}</span>
                                            </div>
                                            <div v-if="item.properties[0]">
                                                <div
                                                    class="ldx-quick-amenity"
                                                    v-if="item.properties[0].number_of_pets > 0"
                                                >
                                                    <img src="@/assets/icons/pet-icon.png" />
                                                    <span>{{ item.properties[0].number_of_pets }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ion-list>
                                </ion-item>
                            </ion-card-content>
                        </ion-card>
                    </div>

                    <!--
                        Blocking Card
                    -->
                    <div v-if="item.state == 'BLOCKED'">
                        <ion-card mode="ios" style="margin: 10px; margin-bottom: 15px; margin-top: 15px">
                            <ion-card-content style="padding: 0px; margin: 5px">
                                <ion-item style="height: 35px" lines="none" class="ion-no-padding">
                                    <ion-label text-wrap style="font-weight: 700; padding-left: 20px"
                                        >{{ formatDate(item.from_date) }} - {{ formatDate(item.to_date) }}</ion-label
                                    >
                                </ion-item>

                                <ion-item lines="none">
                                    <ion-icon
                                        style="width: 75px; margin-bottom: 10px"
                                        color="medium"
                                        :src="closeCircle"
                                    ></ion-icon>
                                    <ion-list style="width: 100%; margin-bottom: 10px">
                                        <p
                                            style="font-weight: 700; font-size: medium; padding-left: 15px; color: red"
                                            text-wrap
                                        >
                                            BLOCKED
                                        </p>
                                        <p
                                            style="font-weight: 500; font-size: small; padding-left: 15px"
                                            text-wrap
                                            v-if="item.properties[0]"
                                        >
                                            {{ item.properties[0].name }}
                                        </p>
                                    </ion-list>
                                </ion-item>
                            </ion-card-content>
                        </ion-card>
                    </div>
                </div>
            </template>
            <div ref="endOfReservationList"></div>
        </div>

        <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px">
            <ion-spinner v-if="loading" color="primary" name="circles"></ion-spinner>
        </div>

        <div
            v-if="total <= 0 && !loading"
            style="
                height: 55%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            "
        >
            <ion-icon :icon="getNoItemsIcon" style="width: 55px; height: 55px; margin-bottom: 10px" color="medium" />
            <ion-label style="font-size: large; font-weight: 700"
                >No {{ getLabelText }} {{ searchValue != '' ? 'Found' : '' }}</ion-label
            >
            <ion-label style="font-size: medium; font-weight: 400">Try adjusting your search criteria</ion-label>
        </div>
        <div
            v-else-if="reservationList.length >= total && reservationList.length"
            style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px"
        >
            <ion-label>No More Results</ion-label>
        </div>

        <template #footer>
            <div style="display: flex; justify-content: center; align-items: center">
                <ion-label style="font-size: larger"> Total: {{ total }} {{ getLabelText }}</ion-label>
            </div>
        </template>
    </base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref, watch } from 'vue';
    import {
        informationCircleOutline,
        searchOutline,
        mailOutline,
        callOutline,
        chatboxEllipsesOutline,
        homeSharp,
        person,
        moon,
        calendar,
        closeCircle,
        book,
        searchCircle,
        options,
        receipt,
        filter,
        documentText,
    } from 'ionicons/icons';
    import AppAPI from '@/services/AppAPI';
    import { format } from 'date-fns';
    import { errorToast } from '@/services/Toast';
    import ModalController from '@/services/ModalController';
    import { timeframeCalculations } from '@/utils/constants';

    export default defineComponent({
        components: {},
        setup() {
            const ionRouter = useIonRouter();
            const reservationList = ref([] as any[]);
            const filterStatus = ref('All');
            const total = ref(0);
            const searchValue = ref('');
            const filterPet = ref('All');
            const endOfReservationList = ref();
            const loading = ref(false);
            const allowFilter = ref(false);

            const filters = ref({
                type: '',
                timeframe: 'Future',
                status: '',
            });

            const getLabelText = computed(() => {
                const isSingle = total.value === 1;
                return filters.value.type == ''
                    ? isSingle
                        ? 'Reservation'
                        : 'Reservations'
                    : isSingle
                    ? 'Block'
                    : 'Blocks';
            });

            const getNoItemsIcon = computed(() => {
                return getLabelText.value.includes('Reservation') ? documentText : closeCircle;
            });

            const fetchReservations = async (reset = false) => {
                while (loading.value) {
                    await new Promise((resolve) => setTimeout(resolve, 50));
                }

                loading.value = true;
                try {
                    const calcTimeframe = {
                        fromDateString: '',
                        toDateString: '',
                    };

                    const { fromDate, toDate } = (
                        timeframeCalculations[filters.value.timeframe] || timeframeCalculations['Default']
                    )();

                    const search = new URLSearchParams(window.location.search).get('search');
                    if (!search) {
                        calcTimeframe.fromDateString = format(fromDate, 'yyyy-MM-dd');
                        calcTimeframe.toDateString = format(toDate, 'yyyy-MM-dd');
                    }

                    try {
                        if (reset) {
                            reservationList.value = [];
                        }
                        const response = await AppAPI.getReservationsList(
                            calcTimeframe.fromDateString,
                            calcTimeframe.toDateString,
                            reservationList.value.length || 0,
                            searchValue.value,
                            filters.value.type ? '' : filters.value.status,
                            filters.value.type
                        );
                        if (response.data.reservations) {
                            allowFilter.value = true;
                            reservationList.value = [...reservationList.value, ...response.data.reservations];
                            total.value = response.data.total;
                        } else {
                            reservationList.value = [...reservationList.value, ...response.data];
                            total.value = response.data.length;
                        }
                    } catch (error) {
                        errorToast('Error loading reservations');
                    }
                } catch (error) {
                    errorToast('Error loading reservations');
                } finally {
                    loading.value = false;
                }
            };

            const formatDate = (date: string) => {
                let tmpDateArray = date.split('-');
                if (tmpDateArray.length <= 1) {
                    return date;
                }
                const month = tmpDateArray[1];
                const day = tmpDateArray[2];
                const year = tmpDateArray[0];

                let fromDateString = month + '/' + day + '/' + year;

                return fromDateString;
            };

            const search = async (value: any) => {
                searchValue.value = value.detail.value;
                await fetchReservations(true);
                createObserver();
            };

            onMounted(async () => {
                const search = new URLSearchParams(window.location.search).get('search');
                if (search) {
                    searchValue.value = search;
                }

                ionRouter.replace({
                    search: '',
                });

                await fetchReservations();
                createObserver();
            });

            const createObserver = () => {
                const observer = new IntersectionObserver(
                    async (entries) => {
                        entries.forEach(async (entry) => {
                            if (entry.isIntersecting) {
                                if (reservationList.value.length < total.value) {
                                    await fetchReservations();
                                    observer.disconnect();
                                    if (endOfReservationList.value) {
                                        observer.observe(endOfReservationList.value);
                                    }
                                }
                            }
                        });
                    },
                    {
                        threshold: 1.0,
                    }
                );
                if (endOfReservationList.value) {
                    observer.observe(endOfReservationList.value);
                }
            };

            const updateFilters = async () => {
                await ModalController.reservationFilterModal(filters.value);
                await fetchReservations(true);
                createObserver();
            };

            return {
                ionRouter,
                informationCircleOutline,
                searchOutline,
                mailOutline,
                callOutline,
                chatboxEllipsesOutline,
                homeSharp,
                person,
                moon,
                calendar,
                closeCircle,
                book,
                searchCircle,
                options,
                receipt,
                reservationList,
                filters,
                filterStatus,
                total,
                searchValue,
                filterPet,
                formatDate,
                search,
                ModalController,
                endOfReservationList,
                getLabelText,
                loading,
                filter,
                updateFilters,
                documentText,
                getNoItemsIcon,
                allowFilter,
            };
        },
    });
</script>
