import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"max-width":"var(--max-width)","margin":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { "page-title": "File Manager" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.currentDirectory != '/LODGIX')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 0,
              style: {"cursor":"pointer"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back())),
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { slot: "start" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("< Back")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  hidden: "",
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentDirectory), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.currentDirectory === '/LODGIX')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 1,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  hidden: "",
                  slot: "start"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("ROOT")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  hidden: "",
                  slot: "end"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("/LODGIX")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (item.name != '' && !item.name.includes('.'))
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  lines: "full",
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, {
                      onClick: ($event: any) => (_ctx.open(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    (!item.name.includes('.'))
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.open(item)),
                          slot: "end"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(">")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_label, {
                      slot: "end",
                      onClick: ($event: any) => (_ctx.deleteCustom(item))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { src: _ctx.trash }, null, 8, ["src"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (item.name != '' && item.name.includes('.'))
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 1,
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, {
                      onClick: ($event: any) => (_ctx.open(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    (!item.name.includes('.'))
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.open(item)),
                          slot: "end"
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(">")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_label, {
                      style: {"cursor":"pointer"},
                      slot: "end",
                      onClick: ($event: any) => (_ctx.deleteCustom(item))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { src: _ctx.trash }, null, 8, ["src"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ]),
    _: 1
  }))
}