import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import API from './API';
import { Device } from '@capacitor/device';
import { format } from 'date-fns';

export default {
    getUserInformation() {
        return API().get('/api/userinfo/user/', {
            method: 'GET',
        });
    },
    getProperties(startDate: string, endDate: string, adults: string, children: string, pets: string, properties: any) {
        let url =
            '/api/properties/property-multi-availabilities/?from_date=' +
            startDate +
            '&to_date=' +
            endDate +
            '&n_adults=' +
            adults +
            '&n_children=' +
            children +
            '&n_pets=' +
            pets;
        if (properties !== null) {
            url = url + '&properties=' + properties;
        }
        return API().get(url, {
            method: 'GET',
        });
    },
    sendTempReservation(
        propertyId: string,
        firstName: string,
        lastName: string,
        email: string,
        fromDate: string,
        toDate: string,
        adults: string,
        children: string,
        pets: string,
        force: boolean,
        guestId: string
    ) {
        //check if guest id and if so then only send guest id and not email/first/last
        if (guestId) {
            return API().post(
                '/api/reservations/reservation/',
                {
                    property_id: propertyId,
                    guest_id: guestId,
                    from_date: fromDate,
                    to_date: toDate,
                    adults: adults,
                    children: children,
                    pets: pets,
                    force: force,
                },
                {
                    method: 'POST',
                }
            );
        } else {
            return API().post(
                '/api/reservations/reservation/',
                {
                    property_id: propertyId,
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    from_date: fromDate,
                    to_date: toDate,
                    adults: adults,
                    children: children,
                    pets: pets,
                    force: force,
                },
                {
                    method: 'POST',
                }
            );
        }
    },
    async sendFinishReservation(reservationId: string, isQuote: boolean, setupIntentId: string, amount: number) {
        return API()
            .put(
                `/api/reservations/reservation/finish/${reservationId}/${isQuote ? 'quote/' : ''}`,
                {
                    setup_intent_id: setupIntentId,
                    amount: amount,
                },
                {
                    method: 'PUT',
                }
            )
            .catch((error) => {
                console.log(error);
            });
    },
    getReservationsList(
        startDate: string,
        endDate: string,
        offset: number,
        search: string,
        status: string,
        type: string
    ) {
        const params = {
            from_date: startDate,
            to_date: endDate,
            limit: '30',
            offset: offset.toString(),
            search: search,
            status: status,
            type: type ? 'BLOCKED' : 'RESERVATIONS',
        };
        const queryString = new URLSearchParams(params).toString();
        return API().get(`/api/reservations/reservation/?${queryString}`, {
            method: 'GET',
        });
    },
    getReservationByInv(invoiceNumber: string) {
        return API().get('/api/reservations/reservation/' + invoiceNumber, {
            method: 'GET',
        });
    },
    searchGuests(email: string, firstName: string, lastName: string) {
        return API().get(
            '/api/guests/guests/search-guests/?email=' + email + '&first_name=' + firstName + '&last_name=' + lastName,
            {
                method: 'GET',
            }
        );
    },
    getGuests(offset: number, search: string) {
        return API().get('/api/guests/guests/?limit=30&offset=' + offset + '&search=' + search, {
            method: 'GET',
        });
    },
    getGuestById(guestId: number) {
        return API().get('/api/guests/guests/' + guestId, {
            method: 'GET',
        });
    },
    sendBlock(propertyId: string, fromDate: string, toDate: string, reason: string, designateAsOwnerBlock: boolean) {
        return API().post(
            '/api/reservations/reservation/block/',
            {
                property_id: propertyId,
                from_date: fromDate,
                to_date: toDate,
                reason: reason,
                designate_as_owner_block: designateAsOwnerBlock,
            },
            {
                method: 'POST',
            }
        );
    },

    getCountries() {
        return API().get('/api/userinfo/utils/countries', {
            method: 'GET',
        });
    },

    getStates(countryId: string) {
        return API().get('/api/userinfo/utils/states/' + countryId, {
            method: 'GET',
        });
    },

    /* Widget/Charts API Calls */

    getAvailablePropertyCount(period: string) {
        return API().get('/api/widgets/widgets/available-property/count/?period=' + period, {
            method: 'GET',
        });
    },
    getArrivalCount(period: string) {
        return API().get('/api/widgets/widgets/arrival/count/?period=' + period, {
            method: 'GET',
        });
    },

    getReservationPending() {
        return API().get('/api/widgets/widgets/pending-reservation/count/', {
            method: 'GET',
        });
    },
    getReservationBalancesDue() {
        return API().get('/api/widgets/widgets/balances-due-reservation/count/', {
            method: 'GET',
        });
    },
    getReservationRefundDue() {
        return API().get('/api/widgets/widgets/refund-due-reservation/count/', {
            method: 'GET',
        });
    },

    getDepartures() {
        return API().get('/api/widgets/widgets/departure-count-by-day/?period_in_days=7', {
            method: 'GET',
        });
    },

    getBookings() {
        return API().get('/api/widgets/widgets/booking-method-by-date/?interval=YEARLY&period=4', {
            method: 'GET',
        });
    },

    getSummaryWidgetData() {
        return API().get('/api/widgets/widgets/summary-statistics/', {
            method: 'GET',
        });
    },

    getPendingWidgetContent(offset: string) {
        return API().get(
            '/api/widgets/widgets/pending-reservation/grid/?limit=8&offset=' + offset + '&desc=false&sort=from_date',
            {
                method: 'GET',
            }
        );
    },

    getArrivalsWidgetContent(offset: string) {
        return API().get(
            '/api/widgets/widgets/arrival/grid/?period=TODAY&limit=8&offset=' + offset + '&desc=false&sort=to_date',
            {
                method: 'GET',
            }
        );
    },

    getDeparturesWidgetContent(offset: string, date = format(new Date(), 'yyyy-MM-dd')) {
        return API().get(
            '/api/widgets/widgets/departure/grid/?date=' +
                date +
                '&limit=8&offset=' +
                offset +
                '&desc=false&sort=from_date',
            {
                method: 'GET',
            }
        );
    },

    getInquiriesWidgetContent(offset: string) {
        return API().get('/api/widgets/widgets/inquiries/grid/?limit=8&offset=' + offset + '&desc=true', {
            method: 'GET',
        });
    },

    //api for devices to lodgix
    createDevice(name: string, registration_id: string, device_id: string, type: string, active: boolean) {
        return API().post(
            '/api/userinfo/device/',
            {
                name: name,
                registration_id: registration_id,
                device_id: device_id,
                type: type,
                active: active,
            },
            {
                method: 'POST',
            }
        );
    },

    deactivateDevice(registration_id: string) {
        if (!registration_id) {
            return Promise.resolve();
        }

        return API().put(
            '/api/userinfo/device/' + registration_id + '/',
            {
                active: false,
            },
            {
                method: 'PUT',
            }
        );
    },

    getDevices(offset: string) {
        return API().get(`/api/userinfo/device/?limit=20&offset=${offset}`, {
            method: 'GET',
        });
    },

    getDeviceById(registration_id: string) {
        return API().get('/api/userinfo/device/' + registration_id + '/', {
            method: 'GET',
        });
    },

    //tmp delete
    deleteDevice(registration_id: string) {
        return API().delete('/api/userinfo/device/' + registration_id + '/', {
            method: 'DELETE',
        });
    },

    //push notifications list
    getPushNotifications() {
        return API().get('/api/userinfo/user/push-notifications', {
            method: 'GET',
        });
    },

    setReadPushNotification(id: string) {
        return API().patch('/api/userinfo/user/push-notifications/set-read/' + id + '/', {
            method: 'PATCH',
        });
    },

    setAllReadPushNotification() {
        return API().patch('/api/userinfo/user/push-notifications/set-read-all/', {
            method: 'PATCH',
        });
    },

    getIntercomHash(deviceType: string) {
        return API().post(
            '/api/userinfo/user/intercom-identity/',
            {
                device: deviceType,
            },
            {
                method: 'POST',
            }
        );
    },

    //get team members & owners
    getTeamMembers() {
        return API().get('/api/team-members/team-members/', {
            method: 'GET',
        });
    },
    getOwners() {
        return API().get('/api/owners/owners/', {
            method: 'GET',
        });
    },

    submitChecklist(data: any, uuid: string) {
        return API().post(
            '/api/housekeeper/job/submit-checklist/',
            {
                data: data,
                uuid: uuid,
            },
            {
                method: 'POST',
            }
        );
    },

    //work orders
    getWorkOrders(showClosed: boolean, offset: number, limit: number) {
        return API().get(
            `/api/work-orders/work-orders/grid/?show_closed=${
                showClosed ? '1' : '0'
            }&offset=${offset}&limit=${limit}&sort=number&desc=true`,
            {
                method: 'GET',
            }
        );
    },
    markWorkOrderAsRead(id: string) {
        return API().post(`/api/work-orders/work-orders/mark-viewed/`, {
            method: 'POST',
            wo_pk: id,
        });
    },
    getWorkOrderDetails(id: string) {
        return API().get(`/api/work-orders/work-orders/${id}/`, {
            method: 'GET',
        });
    },
    async getWorkOrderPhoto(id: string, accessToken: string) {
        const url = process.env.VUE_APP_URL_BASE + '/o' + `/api/work-orders/photo/${id}/`;
        const response = await axios
            .get(url, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: '*/*',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'image/jpeg',
                },
            })
            .catch((error) => {
                console.log(error);
            });
        if (!response) {
            return;
        }
        const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const image = new Image();
        image.src = `data:image/jpeg;base64,${base64}`;
        return image.src;
    },
    getWorkOrderJobTypes() {
        return API().get(`/api/work-orders/work-orders/job-types/`, {
            method: 'GET',
        });
    },
    getWorkOrderVendors() {
        return API().get(`/api/work-orders/work-orders/vendors/`, {
            method: 'GET',
        });
    },
    getWorkOrderExpenseAccounts() {
        return API().get(`/api/work-orders/work-orders/expense-accounts/`, {
            method: 'GET',
        });
    },
    getWorkOrderMaterialExpenses(
        workOrderId: number | string,
        offset: number,
        limit: number,
        sort: string,
        desc: boolean,
        search: string
    ) {
        return API().get(`/api/work-orders/work-orders/material-expenses/`, {
            method: 'GET',
            params: {
                work_order_pk: workOrderId,
                offset: offset,
                limit: limit,
                sort: sort,
                desc: desc,
                search: search,
            },
        });
    },
    createWorkOrderMaterialExpense(data: any) {
        return API().post(`/api/work-orders/work-orders/material-expenses-create/`, {
            method: 'POST',
            ...data,
        });
    },
    deleteWorkOrderMaterialExpense(id: number | string) {
        return API().delete(`/api/work-orders/work-orders/material-expenses-delete/${id}`, {
            method: 'DELETE',
        });
    },
    updateWorkOrderMaterialExpense(data: any, id: number | string) {
        return API().patch(`/api/work-orders/work-orders/material-expenses-update/${id}/`, {
            method: 'PATCH',
            ...data,
        });
    },
    getWorkOrderFormOptions() {
        return API().get(`/api/work-orders/work-orders/form-options/`, {
            method: 'GET',
        });
    },
    getWorkOrderFormProperties() {
        return API().get(`/api/work-orders/work-orders/form-properties/`, {
            method: 'GET',
        });
    },
    getWorkOrderTeamMembers() {
        return API().get(`/api/work-orders/work-orders/team-members/`, {
            method: 'GET',
        });
    },
    getWorkOrderPropertyOwners(propertyId: number) {
        return API().get(`/api/work-orders/work-orders/property-owners/${propertyId}/`);
    },
    updateWorkOrder(data: any) {
        return API().patch(`/api/work-orders/work-orders/update-details/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: 'PATCH',
        });
    },
    getWorkOrderMetadata() {
        return API().get(`/api/work-orders/metadata/`, {
            method: 'GET',
        });
    },
    createWorkOrder(data: any) {
        return API().post(`/api/work-orders/work-orders/create_work_order/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: 'POST',
        });
    },
    async uploadWorkOrderPhoto(data: any) {
        return API().post(`/api/work-orders/work-orders/upload-photo/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
        });
    },

    //device logging
    async logStatistic() {
        if (Capacitor.getPlatform() === 'web') {
            return;
        }

        const deviceInfo = await Device.getInfo();

        const response = API().post(
            '/api/userinfo/statistics/log-statistic/',
            {
                device_os: Capacitor.getPlatform(),
                device_model: deviceInfo.model,
                app_version: process.env.VUE_APP_VERSION,
            },
            {
                method: 'POST',
            }
        );

        return response;
    },

    async updateInvoiceNotes(invoiceId: string, notes: string) {
        return API().patch(
            `/api/reservations/reservation/update-notes/${invoiceId}/`,
            {
                notes: notes,
            },
            {
                method: 'PATCH',
            }
        );
    },

    async getGuestStatusList() {
        return API().get('/api/guests/guests/status-list/', {
            method: 'GET',
        });
    },

    async getGuestInvoices(guestId: string) {
        return API().get(`/api/guests/guests/${guestId}/guest-invoices/`, {
            method: 'GET',
        });
    },

    async getScopes() {
        return API().get('/api/userinfo/user/scopes/', {
            method: 'GET',
        });
    },
};
