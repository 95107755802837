import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './../../../assets/icons/skip-left-all.png'
import _imports_1 from './../../../assets/icons/skip-left.png'
import _imports_2 from './../../../assets/icons/skip-right.png'
import _imports_3 from './../../../assets/icons/skip-right-all.png'


const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 1,
  style: {"display":"flex","justify-content":"space-between","width":"100%","white-space":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, {
        style: _normalizeStyle(_ctx.headerStyle)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, { style: {"font-size":"22px","text-align":"left"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["style"]),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          (_ctx.list.length <= 0)
            ? (_openBlock(), _createBlock(_component_ion_label, {
                key: 0,
                style: {"display":"flex","justify-content":"center","font-weight":"700"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.noDataLabel), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.list.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                (_ctx.list.length >= 8 || _ctx.listSize >= 8)
                  ? (_openBlock(), _createBlock(_component_ion_label, {
                      key: 0,
                      style: {"display":"flex","justify-content":"center","padding-top":"8px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.offset + 1) + " to " + _toDisplayString(_ctx.list.length + _ctx.offset) + " of " + _toDisplayString(_ctx.totalCount), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.list.length >= 8 || _ctx.listSize >= 8)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.start())),
                          color: "primary",
                          class: "widget-content-list-button",
                          size: "default"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createElementVNode("img", { src: _imports_0 }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.back())),
                          color: "primary",
                          class: "widget-content-list-button",
                          size: "default"
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createElementVNode("img", { src: _imports_1 }, null, -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.next())),
                          color: "primary",
                          class: "widget-content-list-button",
                          size: "default"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createElementVNode("img", { src: _imports_2 }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.end())),
                          color: "primary",
                          class: "widget-content-list-button",
                          size: "default"
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("img", { src: _imports_3 }, null, -1)
                          ])),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}