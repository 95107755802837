import { resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/vip.svg'
import _imports_1 from '@/assets/icons/blocklist.svg'


const _hoisted_1 = {
  class: "ion-no-padding item",
  style: {"--background":"transparent","margin":"10px","margin-top":"0px","margin-bottom":"0px"}
}
const _hoisted_2 = {
  class: "ion-no-padding item",
  style: {"--background":"transparent","margin":"10px","margin-top":"0px","margin-bottom":"0px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  slot: "end",
  style: {"padding-right":"0px","margin-left":"10px"},
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  slot: "end",
  style: {"padding-right":"0px","margin-left":"10px"},
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_ctx.data)
    ? (_openBlock(), _createBlock(_component_modal_base_layout, {
        key: 0,
        ref: "modalBaseLayoutRef",
        title: "Control Panel",
        background: "rgb(239, 246, 255)"
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "modal-footer-button",
                        color: "danger",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalBaseLayoutRef.cancel()))
                      }, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode("Close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "modal-footer-button",
                        color: "primary",
                        onClick: _ctx.createNewReservation
                      }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode("Add Reservation")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.data.conversationId)
                ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            class: "modal-footer-button",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openConversation(_ctx.data.conversationId)))
                          }, {
                            default: _withCtx(() => _cache[27] || (_cache[27] = [
                              _createTextVNode("View Conversation")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_ctx.statusDetails)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.statusDetails.class),
                style: _normalizeStyle(_ctx.statusDetails.style)
              }, [
                _createElementVNode("p", {
                  style: _normalizeStyle(_ctx.statusDetails.titleStyle)
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.statusDetails.icon), {
                    style: _normalizeStyle(_ctx.statusDetails.imgStyle)
                  }, null, 8, ["style"])),
                  _createTextVNode(_toDisplayString(_ctx.statusDetails.title), 1)
                ], 4),
                _createElementVNode("p", null, [
                  _createTextVNode(" Reservation ID: #" + _toDisplayString(_ctx.data.reservationId) + " ", 1),
                  _createVNode(_component_ion_icon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AppFunctions.copyText(_ctx.data.reservationId))),
                    style: {"margin-bottom":"-2px","cursor":"pointer"},
                    icon: _ctx.copyOutline,
                    label: "Copy Reservation ID"
                  }, null, 8, ["icon"]),
                  _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.data.bookingMethodName) + " • " + _toDisplayString(_ctx.data.createdDate), 1)
                ])
              ], 6))
            : _createCommentVNode("", true),
          _cache[28] || (_cache[28] = _createElementVNode("p", { style: {"margin":"10px","padding-top":"20px","font-size":"x-large","color":"#20469b","font-weight":"700"} }, " Reservation Details ", -1)),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.properties, (item, index) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: index,
                class: "ion-no-padding",
                lines: "none",
                style: {"--background":"transparent","width":"100%","border-bottom":"none","margin":"0px"}
              }, {
                default: _withCtx(() => [
                  (index == 0)
                    ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Property")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (item.room_id)
                    ? (_openBlock(), _createBlock(_component_ion_label, {
                        key: 1,
                        slot: "end",
                        style: {"text-align":"end"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name) + " - " + _toDisplayString(item.room_id), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (!item.room_id)
                    ? (_openBlock(), _createBlock(_component_ion_label, {
                        key: 2,
                        slot: "end",
                        style: {"text-align":"end"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Adults")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.guests), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Children")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.children), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.properties, (item, index) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: index,
                class: "ion-no-padding",
                lines: "none",
                style: {"--background":"transparent","width":"100%","border-bottom":"none","margin":"0px"}
              }, {
                default: _withCtx(() => [
                  (index == 0)
                    ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("Pets")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.data.properties.length == 1)
                    ? (_openBlock(), _createBlock(_component_ion_label, {
                        key: 1,
                        slot: "end",
                        style: {"text-align":"end"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.number_of_pets), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_ctx.data.properties.length > 1)
                    ? (_openBlock(), _createBlock(_component_ion_label, {
                        key: 2,
                        slot: "end",
                        style: {"text-align":"end"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.number_of_pets) + " pets at " + _toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Arrival")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.data.startDate)) + " ", 1),
                  (_ctx.data.checkInTime)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "• " + _toDisplayString(_ctx.formatTime(_ctx.data.checkInTime)), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("Departure")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.data.endDate)) + " ", 1),
                  (_ctx.data.checkOutTime)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "• " + _toDisplayString(_ctx.formatTime(_ctx.data.checkOutTime)), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            style: {"border-bottom":"none"},
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Invoice Notes")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            style: {"border-bottom":"none","align-items":"start"},
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, {
                style: _normalizeStyle([_ctx.tmpNotes ? '' : 'font-weight:bold; text-decoration:underline;'])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.tmpNotes || 'None'), 1)
                ]),
                _: 1
              }, 8, ["style"]),
              _createVNode(_component_ion_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateNotes())),
                style: {"max-width":"40px","max-height":"40px","height":"40px","width":"40px"},
                slot: "end"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: _ctx.pencilOutline }, null, 8, ["src"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _cache[29] || (_cache[29] = _createElementVNode("div", { style: {"border-bottom-style":"solid","border-bottom-color":"rgb(200, 200, 200)","border-bottom-width":"1px","padding-top":"2px"} }, null, -1)),
          _cache[30] || (_cache[30] = _createElementVNode("p", { style: {"margin":"10px","padding-top":"20px","font-size":"x-large","color":"#20469b","font-weight":"700"} }, " Guest Details ", -1)),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode("Name")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                "text-wrap": "",
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.guest?.full_name), 1)
                ]),
                _: 1
              }),
              (_ctx.data.guest?.is_vip)
                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.data.guest?.is_blocklist)
                ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.data.guest?.phone)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 1,
                class: "ion-no-padding item",
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode("Email")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, {
                    "text-wrap": "",
                    slot: "end",
                    style: {"text-align":"end"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.guest?.email), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    style: {"margin-left":"10px","cursor":"pointer"},
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.AppFunctions.openEmail(_ctx.data.guest?.email))),
                    icon: _ctx.mailOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_item, {
                key: 2,
                class: "ion-no-padding item",
                style: {"border-bottom":"none"},
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Email")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, {
                    "text-wrap": "",
                    slot: "end",
                    style: {"text-align":"end"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.guest?.email), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    style: {"margin-left":"10px","cursor":"pointer"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.AppFunctions.openEmail(_ctx.data.guest?.email))),
                    icon: _ctx.mailOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })),
          (_ctx.data.guest?.phone)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 3,
                class: "ion-no-padding item",
                style: {"border-bottom":"none"},
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("Phone")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, {
                    "text-wrap": "",
                    slot: "end",
                    style: {"text-align":"end"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.guest?.phone), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    style: {"margin-left":"10px","cursor":"pointer"},
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.AppFunctions.openPhone(_ctx.data.guest?.phone))),
                    icon: _ctx.callOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    style: {"margin-left":"10px","cursor":"pointer"},
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.AppFunctions.openText(_ctx.data.guest?.phone))),
                    icon: _ctx.chatboxEllipsesOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _cache[31] || (_cache[31] = _createElementVNode("div", { style: {"border-bottom-style":"solid","border-bottom-color":"rgb(200, 200, 200)","border-bottom-width":"1px"} }, null, -1)),
          _cache[32] || (_cache[32] = _createElementVNode("p", { style: {"margin":"10px","padding-top":"20px","font-size":"x-large","color":"#20469b","font-weight":"700"} }, " Payment Summary ", -1)),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("Total")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.total)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.data.confirmationAmount >= 0)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 4,
                class: "ion-no-padding item",
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("Confirmation Amount")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, {
                    slot: "end",
                    style: {"text-align":"end"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.confirmationAmount)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            style: {"border-bottom":"none"},
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("Total with Deposits")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.totalDeposits)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _cache[33] || (_cache[33] = _createElementVNode("div", { style: {"border-bottom-style":"solid","border-bottom-color":"rgb(200, 200, 200)","border-bottom-width":"1px"} }, null, -1)),
          _createVNode(_component_ion_item, {
            class: "ion-no-padding item",
            style: {"border-bottom":"none"},
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode("Paid")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.paid)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512))
    : _createCommentVNode("", true)
}