<template>
    <modal-base-layout :title="`Guest Details`" background="#f4f5f8">
        <ion-card v-if="guest">
            <ion-card-header class="header-container">
                <div class="guest-info">
                    <ion-card-title>
                        {{ guest.title?.name || '' }} {{ guest.first_name }} {{ guest.last_name }}
                    </ion-card-title>
                    <ion-card-subtitle>{{ guest.email }}</ion-card-subtitle>
                </div>
            </ion-card-header>

            <ion-card-content>
                <ion-list lines="full">
                    <ion-item>
                        <ion-label>Address</ion-label>
                        <div class="address">
                            <p v-if="guest.address.address1">{{ guest.address.address1 }}</p>
                            <p v-if="guest.address.address2">{{ guest.address.address2 }}</p>
                            <p v-if="guest.address.city || guest.address.state?.code || guest.address.zip">
                                {{ guest.address.city }}, {{ guest.address.state?.code || '' }} {{ guest.address.zip }}
                            </p>
                            <p>{{ guest.address.country?.name || '' }}</p>
                        </div>
                    </ion-item>
                    <ion-item v-if="guest.date_of_birth">
                        <ion-label>Date of Birth</ion-label>
                        <ion-text>{{ dob }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.status_id">
                        <ion-label>Guest's Status</ion-label>
                        <ion-text>{{ statusLabel }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.mobile_phone">
                        <ion-label>Phone (mobile)</ion-label>
                        <ion-text>{{ guest.mobile_phone }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.phone">
                        <ion-label>Phone (home)</ion-label>
                        <ion-text>{{ guest.phone }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.work_phone">
                        <ion-label>Phone (work)</ion-label>
                        <ion-text>{{ guest.work_phone }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.work_phone_ext">
                        <ion-label>Ext</ion-label>
                        <ion-text>{{ guest.work_phone_ext }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.fax">
                        <ion-label>Fax</ion-label>
                        <ion-text>{{ guest.fax }}</ion-text>
                    </ion-item>
                    <ion-item v-if="guest.note">
                        <ion-label>Notes</ion-label>
                        <ion-text>{{ guest.note }}</ion-text>
                    </ion-item>
                </ion-list>

                <p class="note">Note: Guest details can only be edited on Lodgix.com.</p>
            </ion-card-content>
        </ion-card>

        <ion-card v-if="invoices && invoices.length > 0">
            <ion-card-header
                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
            >
                <ion-card-title>Recent Invoices</ion-card-title>
                <ion-button @click="viewAllInvoices()">View All</ion-button>
            </ion-card-header>
            <ion-card-content>
                <swiper-container
                    :slides-per-view="1"
                    :centered-slides="true"
                    :navigation="true"
                    :pagination="true"
                    style="border-radius: 10px !important"
                >
                    <template v-for="invoice in invoices" :key="invoice">
                        <swiper-slide style="border-radius: 10px !important; padding: 0 40px">
                            <h3 style="font-size: 18px; font-weight: 700">Invoice #{{ invoice.id }}</h3>
                            <ion-list lines="full">
                                <ion-item>
                                    <ion-label>Property</ion-label>
                                    <ion-text>{{ invoice.properties[0].name || 'N/A' }}</ion-text>
                                </ion-item>
                                <ion-item>
                                    <ion-label>From Date</ion-label>
                                    <ion-text>{{ formatDate(invoice.from_date) }}</ion-text>
                                </ion-item>
                                <ion-item>
                                    <ion-label>To Date</ion-label>
                                    <ion-text>{{ formatDate(invoice.to_date) }}</ion-text>
                                </ion-item>
                                <ion-item>
                                    <ion-label>Total Amount</ion-label>
                                    <ion-text>{{ formatPrice(invoice.total) }}</ion-text>
                                </ion-item>
                            </ion-list>
                        </swiper-slide>
                    </template>
                </swiper-container>
            </ion-card-content>
        </ion-card>

        <div v-else-if="invoices && invoices.length <= 0">
            <ion-card>
                <ion-card-header>
                    <ion-card-title>No Invoices for Guest</ion-card-title>
                </ion-card-header>
            </ion-card>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="cancel()">Back</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { errorToast } from '@/services/Toast';
    import AppFunctions from '@/services/AppFunctions';
    import { formatDate } from '@/utils/Date';

    export default defineComponent({
        components: {},
        props: {
            guestId: {
                type: Number,
                required: false,
                default: null,
            },
        },
        setup(props) {
            const store = useStore();
            const guest = ref();
            const statusList = ref();
            const invoices = ref();

            const dob = computed(() => {
                if (guest.value && guest.value.date_of_birth) {
                    return format(new Date(guest.value.date_of_birth), 'MM/dd/yyyy');
                }
                return 'N/A';
            });

            const statusLabel = computed(() => {
                if (statusList.value && guest.value) {
                    const status = statusList.value.find((status: any) => status.id === guest.value.status_id);
                    return status ? status.name : '';
                }
                return '';
            });

            const cancel = async () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                return modalController.dismiss(null, 'cancel');
            };

            const viewAllInvoices = async () => {
                AppFunctions.transitionClear(`/reservationsList?search=${props.guestId}`);
                return modalController.dismiss(null, 'cancel');
            };

            const formatPrice = (value: any) => {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: store.userCurrency,
                });
                return format.format(value);
            };

            onMounted(async () => {
                if (props.guestId) {
                    const response = await AppAPI.getGuestById(props.guestId);
                    if (!response) {
                        errorToast('Failed to fetch guest');
                        cancel();
                    } else {
                        guest.value = response.data;
                    }

                    statusList.value = (await AppAPI.getGuestStatusList()).data;
                    invoices.value = (await AppAPI.getGuestInvoices(props.guestId.toString())).data;
                }
            });

            return {
                store,
                guest,
                statusLabel,
                dob,
                invoices,
                formatDate,
                cancel,
                confirm,
                formatPrice,
                viewAllInvoices,
            };
        },
    });
</script>

<style scoped>
    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
    }

    .guest-info {
        width: 100%;
        text-align: center;
    }

    .address {
        text-align: right;
    }

    ion-card-title,
    ion-card-subtitle {
        margin: 0;
    }

    ion-card-content ion-item {
        margin-bottom: 8px;
    }

    .note {
        padding-top: 5px;
        font-style: italic;
        color: #666;
    }
</style>
